import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
const enquirySend = async(postdata) => {
    let url  = process.env.REACT_APP_BASE_URL+`enquirysend`
    let result = await axios.post(url, postdata);
    return result?.data;
}
  const useEnquiry = (postdata) => {
    // const queryClient = useQueryClient();
    return useMutation({
        mutationFn : (postdata) => enquirySend(postdata),
        onSuccess : (data) => {
            // console.log("response Success", data);
            // queryClient.invalidateQueries({
            //     queryKey : ['userlist', data?.data]
            // })
        }
    })
}

export default useEnquiry;
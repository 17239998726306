import React, { useEffect } from "react";
import "../assets/styles/university.css";
import abroadUniversity from "../assets/images/universities/abroadUniversity.jpg";
import { Rate } from "antd";
import { useParams } from "react-router-dom";
import nepal from "../assets/images/flags/nepal.png";
import logoEcfmg from "../assets/images/universities/logoEcfmg.png";
import { useState } from "react";
import { FaSquare } from "react-icons/fa6";
import { IoMdCloseCircleOutline } from "react-icons/io";
import brochure from "../assets/images/modal/brochure.jpg";
import apply from "../assets/images/modal/apply.jpg";
import axios from "axios";
import useEnquiry from "../useHooks/useEnquiry";
import { useQuery } from "react-query";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

const UniversityDetails = () => {
  const { slugunviersity } = useParams();
  const {mutateAsync} = useEnquiry();
  const [activeTab, setActiveTab] = useState("overview");
  const [file, setFile] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [applyModal, setApplyModal] = useState(false);
  const { register, handleSubmit, formState:{errors},reset } = useForm();
  const handleFileChange = (event) => {
    // Access the selected file
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    console.log(file);
  };

  const universityDetail = async () => {
    const newurl =
      process.env.REACT_APP_BASE_URL + `university/${slugunviersity}`;
    const response = await axios.get(newurl);
    return response?.data?.data;
  };

  const { data } = useQuery({
    queryKey: ["universityDetail"],
    queryFn: () => universityDetail(),
  });

  const onSubmit = (data) => {
    mutateAsync(data).then((resp) => {
      console.log("data ", resp?.status);
      if(resp?.status===true){
        toast.success("Mail send sucessfully!");
        reset();
      }
    })
  }

  const myHtmlcomponenet = ({ htmlString }) => {
    // Use a regular expression to find the oembed element in the HTML string
    const oembedRegex = /<oembed[^>]*>/g;
    const oembedMatch = htmlString.match(oembedRegex);

    // If an oembed element was found, convert it to an iframe element
    if (oembedMatch) {
      const oembedUrl = oembedMatch[0].match(/url="([^"]*)"/)[1];
      const iframeElement = `<iframe src="${oembedUrl}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
      htmlString = htmlString.replace(oembedRegex, iframeElement);
    }

    return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
  };

  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Univesity Detail | {data?.title}</title>
        <meta name="description" content="ims education about page" />
        <link rel="canonical" href={`https://imseduglobal.com/university/${data?.slug}`} />
      </Helmet>
      <section className="university_section_detail">
        <div className="container">
          <div className="university_details_wrapper">
            <div className="university_detail_left">
              <div className="university_card">
                <div className="university_thmb">
                  <img src={data?.hero_image} alt="" />
                  <span className="star_content">
                    <Rate defaultValue="5" />
                  </span>
                  <div className="university_name">
                    <h1>{slugunviersity} University</h1>
                  </div>
                </div>

                <div className="university_space_content">
                  <div className="university_content_top">
                    <div className="university_content_top_left">
                      <figure className="university_top_left_img">
                        <img src={data?.logo_image} alt="" />
                      </figure>
                      <figure>
                        <img
                          src={logoEcfmg}
                          alt=""
                          style={{ height: "27px", width: "90px" }}
                        />
                      </figure>
                    </div>

                    <div className="university_content_top_right">
                      <h3 className="resp_uni_header">{data?.title}</h3>
                      <ul className="university_detail_top_right_lists">
                        <li>
                          <div className="uni_list">
                            <figure>
                              <img src={nepal} alt="" />
                            </figure>
                            <div>
                              <h5>{data?.country}</h5>
                            </div>
                          </div>
                        </li>
                        <li style={{ color: "gray" }}>|</li>
                        <li>
                          <div className="uni_list">
                            <h5>{data?.course_id}</h5>
                          </div>
                        </li>
                      </ul>
                      <div className="university_btn_grp">
                        <div
                          className="download_btn"
                          onClick={() => setShowModal(true)}
                        >
                          Brouchure
                        </div>

                        <div
                          className="apply_now_btn"
                          onClick={() => setApplyModal(true)}
                        >
                          Apply Now
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="university_bottom_content">
                    <ul className="uni_tab_lists">
                      <li
                        className={
                          activeTab === "overview"
                            ? "tab_list active"
                            : "tab_list"
                        }
                        onClick={() => setActiveTab("overview")}
                      >
                        <FaSquare />
                        <h5>Overview</h5>
                      </li>
                      <li
                        className={
                          activeTab === "eligibility"
                            ? "tab_list active"
                            : "tab_list"
                        }
                        onClick={() => setActiveTab("eligibility")}
                      >
                        <FaSquare />
                        <h5>Eligibility</h5>
                      </li>
                      <li
                        className={
                          activeTab === "fees" ? "tab_list active" : "tab_list"
                        }
                        onClick={() => setActiveTab("fees")}
                      >
                        <FaSquare />
                        <h5>Fees Structre</h5>
                      </li>
                      <li
                        className={
                          activeTab === "campus"
                            ? "tab_list active"
                            : "tab_list"
                        }
                        onClick={() => setActiveTab("campus")}
                      >
                        <FaSquare />
                        <h5>Campus Life</h5>
                      </li>
                      <li
                        className={
                          activeTab === "hostel"
                            ? "tab_list active"
                            : "tab_list"
                        }
                        onClick={() => setActiveTab("hostel")}
                      >
                        <FaSquare />
                        <h5>Hostel & Facilities</h5>
                      </li>
                      <li
                        className={
                          activeTab === "gallery"
                            ? "tab_list active"
                            : "tab_list"
                        }
                        onClick={() => setActiveTab("gallery")}
                      >
                        <FaSquare />
                        <h5>Gallery</h5>
                      </li>
                      <li
                        className={
                          activeTab === "reviews"
                            ? "tab_list active"
                            : "tab_list"
                        }
                        onClick={() => setActiveTab("reviews")}
                      >
                        <FaSquare />
                        <h5>Reviews</h5>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {activeTab === "overview" && (
                <div className="university_detail_content">
                  <div className="uni_overview_lists">
                    <ul className="university_detail_lists">
                      <li>
                        <div className="data--head">University Type</div>
                        <div className="data--content">
                          : {data?.university_type}
                        </div>
                      </li>
                      <li>
                        <div className="data--head">University Grade</div>
                        <div className="data--content">
                          : {data?.university_grade}
                        </div>
                      </li>
                      <li>
                        <di className="data--head" v>
                          University Estd
                        </di>
                        <div className="data--content">: {data?.estd_year}</div>
                      </li>
                      <li>
                        <div className="data--head">ECFMG Status</div>
                        <div className="data--content">: {data?.ecfmg}</div>
                      </li>
                      <li>
                        <div className="data--head">Program Type</div>
                        <div className="data--content">: Paid</div>
                      </li>
                    </ul>
                    <ul className="university_detail_lists">
                      <li>
                        <div className="data--head">Total Course Fee</div>
                        <div className="data--content">: {data?.fee}</div>
                      </li>
                      <li>
                        <div className="data--head">Total Course Duration</div>
                        <div className="data--content">: {data?.duration}</div>
                      </li>
                    </ul>
                  </div>

                  <div
                    className="uni_overview_desc"
                    dangerouslySetInnerHTML={{ __html: data?.overview }}
                  >
                    {/* {(data?.overview) ? myHtmlcomponenet(data?.overview) : ""} */}
                  </div>
                </div>
              )}
              {activeTab === "eligibility" && (
                <div
                  className="university_detail_content"
                  dangerouslySetInnerHTML={{ __html: data?.eligbilty }}
                ></div>
              )}
              {activeTab === "fees" && (
                <div className="university_detail_content">
                  <div
                    className="table_responsive"
                    dangerouslySetInnerHTML={{ __html: data?.fee_structure }}
                  ></div>
                </div>
              )}
              {activeTab === "campus" && (
                <div className="university_detail_content">
                  <div
                    className="uni_overview_desc"
                    dangerouslySetInnerHTML={{ __html: data?.campus_life }}
                  ></div>
                </div>
              )}
              {activeTab === "hostel" && (
                <div className="university_detail_content">
                  <div
                    className="uni_overview_desc"
                    dangerouslySetInnerHTML={{
                      __html: data?.hostel_facilities,
                    }}
                  ></div>
                </div>
              )}
              {activeTab === "gallery" && (
                <div className="university_gallery_content">
                  {data?.gallery?.map((row) => (
                    <div className="gallery-box">
                      <img src={row} width="200px" alt="" />
                    </div>
                  ))}
                </div>
              )}
              {activeTab === "reviews" && (
                <div className="university_detail_content">
                  <h3>Enter Your Reviews</h3>
                  <div className="university_form_wrapper">
                    <div className="uni_form_thu">
                      <img
                        src={abroadUniversity}
                        alt=""
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="uni_form">
                      <form action="">
                        <div className="form-content">
                          <input type="text" placeholder="Enter Your Name" />
                        </div>
                        <div className="form-content">
                          <input type="email" placeholder="Enter Your Email" />
                        </div>
                        <div
                          className="form-content"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label htmlFor="">Upload Image/video</label>
                          <input type="file" onChange={handleFileChange} />
                        </div>
                        <div className="form-content">
                          <textarea
                            name=""
                            id=""
                            cols="30"
                            rows="10"
                            placeholder="Enter Your Message"
                          ></textarea>
                        </div>

                        <a className="apply_btn">Add Review</a>
                      </form>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="university_detail_right">
              <div className="counsul_form_wrapp">
                <div className="title">
                  <h5>Talk to Our Counceller</h5>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-content">
                    <input type="text" name="name" {...register("name", { required: true })} placeholder="Enter Your Name" />
                    {errors.name && <p>This field is required</p>}
                  </div>
                  <div className="form-content">
                    <input type="email"  name="email" {...register("email", { required: true })} placeholder="Enter Your Email" />
                    {errors.email && <p>This email is required</p>}
                  </div>
                  <div className="form-content">
                    <input type="number" name="number" {...register("number", { required: true })} placeholder="Enter Whatsapp Number" />
                    {errors.number && <p>This number is required</p>}
                  </div>
                  <div className="form-content">
                    <input type="submit" value="Submit" className="apply_btn" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* modal */}

      {showModal && (
        <section className="modal_wrapper" onClick={() => setShowModal(false)}>
          <div className="modal_container">
            <div className="modal_title">
              <h2>Download Brouchre</h2>
            </div>

            <button className="close_btn" onClick={() => setShowModal(false)}>
              <IoMdCloseCircleOutline fontSize="25px" />
            </button>
            <div className="my_modal">
              <div className="modal_thumb">
                <img src={brochure} alt="" style={{ width: "100%" }} />
              </div>
              <div className="modal_desc">
                <form action="">
                  <div className="form-content">
                    <input type="text" placeholder="Enter Full Name" />
                  </div>
                  <div className="form-content">
                    <input type="number" placeholder="Enter Whatsapp Number" />
                  </div>
                  <div className="form-content">
                    <input type="email" placeholder="Enter Email Id" />
                  </div>
                  <a className="apply_btn">Submit</a>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}

      {applyModal && (
        <section className="modal_wrapper" onClick={() => setApplyModal(false)}>
          <div className="modal_container">
            <div className="modal_title">
              <h2>Apply And Book Your University</h2>
            </div>

            <button className="close_btn" onClick={() => setApplyModal(false)}>
              <IoMdCloseCircleOutline fontSize="25px" />
            </button>
            <div className="my_modal">
              <div className="modal_thumb">
                <img src={apply} alt="" style={{ width: "100%" }} />
              </div>
              <div className="modal_desc">
                <form action="">
                  <div className="form-content">
                    <input type="text" placeholder="Enter Full Name" />
                  </div>
                  <div className="form-content">
                    <input type="number" placeholder="Enter Whatsapp Number" />
                  </div>

                  <a className="apply_btn">Submit</a>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default UniversityDetails;

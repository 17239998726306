import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "../assets/styles/StudyAbroad.css";
import { FaMinus, FaPlus, FaStar, FaRegStar } from "react-icons/fa6";
import { CiFilter } from "react-icons/ci";
import { IoMdCloseCircleOutline } from "react-icons/io";
import brochure from "../assets/images/modal/brochure.jpg";
import apply from "../assets/images/modal/apply.jpg";
import axios from "axios";
import { useInfiniteQuery, useQuery } from "react-query";
import useDebounce from "../components/useDebounce";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import useEnquiry from "../useHooks/useEnquiry";
import { Helmet } from "react-helmet";


const StudyAbroad = () => {
  const { countrycode,coursecode } = useParams();


  const { register, setValue, handleSubmit, formState:{errors},reset } = useForm();
  const [brocherDowload, setBrocherDowload] = useState("");
  const [selectedMenu, setSelectedMenu] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [applyModal, setApplyModal] = useState(false);
  const [percent, setPercent] = useState(Number(0));
  const [amountBudget, setAmountBudget] = useState(50000);
  const [ratingcount, setRatingCount] = useState(0);
  const [search, setSearch] = useState(0);
  const {mutateAsync} = useEnquiry();
  const [filters, setFilters] = useState({
    course: "",
    country: countrycode,
    unvi_type: "",
    degree: "",
    perpage: 5,
    keyword: "",
  });

  const download = () => {
    var link = document.createElement("a");
    link.download = 'brochure-download.pdf';
    link.href = brocherDowload;
    document.body.appendChild(link);
    link.click();
  }
  
         

  const searchParams = useDebounce([filters, amountBudget, search], 1000);

  const LIMIT = 5;

  const onSubmit = (data) => {
    mutateAsync(data).then((resp) => {
      console.log("data ", resp?.status);
      if(resp?.status===true){
        toast.success("Mail send sucessfully!");
        if(brocherDowload && brocherDowload!==""){
            download();
        }
        
        reset();
      }
    })
  }

  const getcourse = async () => {
    const url = process.env.REACT_APP_BASE_URL + "courses";
    const response = await axios.get(url);
    // setCourse(response?.data);
    return response?.data;
  };

  const country = async () => {
    const url = process.env.REACT_APP_BASE_URL + "country";
    const response = await axios.get(url);
    return response?.data;
  };

  const unviersity = async ({ pageParam, filters }) => {
    const newurl =
      process.env.REACT_APP_BASE_URL +
      `university?course=${filters?.course}&country=${filters?.country}&university_type=${filters?.unvi_type}&keyword=${search}&degree=${filters?.degree}&budget=${amountBudget}&page=${pageParam}&perpage=${filters?.perpage}`;
    const response = await axios.get(newurl);
    console.log("Response =>", response);
    return response?.data?.data?.data;
  };

  const { data: courseData } = useQuery({
    queryKey: ["courseData"],
    queryFn: () => getcourse(),
  });

  const { data: countryData } = useQuery({
    queryKey: ["countryData"],
    queryFn: () => country(),
  });
  const {
    data: universityData,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,} = useInfiniteQuery({
      queryKey: ['unversityData', searchParams],
      queryFn: ({ pageParam = 1}) => unviersity({pageParam, filters}),
      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages) => {
        // console.log(lastPage,allPages, "last Paghe");
        const nextPage =
          lastPage.length === LIMIT ? allPages.length + 1 : undefined;
        return nextPage;

      },
  });

  const handleFilterChange = (filterName, value) => {
    setFilters({
      ...filters,
      [filterName]: value,
    });
  };


 


  const increase = () => {
    setPercent((prevPercent) => {
      const newPercent = prevPercent + 10;
      if (newPercent > 100) {
        return 100;
      }
      return newPercent;
    });
  };

  const decline = () => {
    setPercent((prevPercent) => {
      const newPercent = prevPercent - 10;
      if (newPercent < 0) {
        return 0;
      }
      return newPercent;
    });
  };

  const clearAll = () => {
    setFilters({
      course: "",
      country: "",
      degree: "",
      unvi_type: "",
      perpage: 5,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleDownloadBrochure = (value, university) => {
    setShowModal(true);
    setBrocherDowload(value);
    setValue('university', university);
    setValue('download', 'broucher downlod');
  }

  const handleApplyNow = (value) => {
    setApplyModal(true);
    setValue('university', value);
  }


  

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Studay in Abroad | Ims Education Global</title>
        <meta name="description" content="ims education about page" />
        <link rel="canonical" href="https://imseduglobal.com/about" />
      </Helmet>
      <section className="study_abroad_section">
        <div className="container">
          <div className="study_wrapper">
            <div
              className={
                openModal
                  ? "resp_modal animate__animated animate__fadeInDown"
                  : "study_lft"
              }
            >
              {openModal ? (
                <div className="resp_modal">
                  <div className="filter_section">
                    <div className="filter_title">
                      <h4>Filters</h4>
                      <p className="clear_all" onClick={() => clearAll()}>
                        Clear All
                      </p>
                      <p
                        className="resp_menu"
                        onClick={() => setOpenModal(false)}
                      >
                        <IoMdCloseCircleOutline fontSize="20px" />
                      </p>
                    </div>
                    <div className="filterclear">
                      {filters?.course !== "" ? (
                        <span className="filterbtn">{filters?.course}</span>
                      ) : (
                        ""
                      )}
                      {filters?.unvi_type !== "" ? (
                        <span className="filterbtn">{filters?.unvi_type}</span>
                      ) : (
                        ""
                      )}
                      {filters?.country !== "" ? (
                        <span className="filterbtn">{filters?.country}</span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="filter_card">
                      <div
                        className="filter_tit"
                        onClick={() =>
                          setSelectedMenu(
                            selectedMenu === "courses" ? null : "courses"
                          )
                        }
                      >
                        <p>Courses</p>
                        <div>
                          {selectedMenu === "courses" ? (
                            <FaMinus />
                          ) : (
                            <FaPlus />
                          )}
                        </div>
                      </div>

                      {selectedMenu === "courses" && (
                        <>
                          <ul>
                            {courseData?.data?.map((row, index) => {
                              return (
                                <li
                                  className="select_item"
                                  onClick={() =>
                                    handleFilterChange("course", row.name)
                                  }
                                >
                                  <div
                                    className={
                                      filters?.course === row.name
                                        ? "checkbox selected"
                                        : "checkbox"
                                    }
                                  ></div>
                                  <div className="check_label">{row.name}</div>
                                </li>
                              );
                            })}
                          </ul>
                          <div className="filter_btn_group">
                            <a href="" className="filter_btn">
                              Diploma
                            </a>
                            <a href="" className="filter_btn">
                              Bachelor
                            </a>
                            <a href="" className="filter_btn">
                              Master
                            </a>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="filter_card">
                      <div
                        className="filter_tit"
                        onClick={() =>
                          setSelectedMenu(
                            selectedMenu === "country" ? null : "country"
                          )
                        }
                      >
                        <p>Country</p>
                        <div>
                          {selectedMenu === "country" ? (
                            <FaMinus />
                          ) : (
                            <FaPlus />
                          )}
                        </div>
                      </div>

                      {selectedMenu === "country" && (
                        <ul>
                          {countryData?.data?.map((country) => {
                            return (
                              <li
                                className="select_item"
                                onClick={() =>
                                  handleFilterChange("country", country.name)
                                }
                              >
                                <div
                                  className={
                                    filters?.country === country.name
                                      ? "checkbox selected"
                                      : "checkbox"
                                  }
                                ></div>
                                <div className="check_label">
                                  {country.name}
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </div>

                    <div className="filter_card">
                      <div
                        className="filter_tit"
                        onClick={() =>
                          setSelectedMenu(
                            selectedMenu === "type" ? null : "type"
                          )
                        }
                      >
                        <p> University Type</p>
                        <div>
                          {selectedMenu === "type" ? <FaMinus /> : <FaPlus />}
                        </div>
                      </div>
                      {selectedMenu === "type" && (
                        <ul>
                          <li
                            className="select_item"
                            onClick={() =>
                              handleFilterChange("unvi_type", "Public")
                            }
                          >
                            <div
                              className={
                                filters?.unvi_type === "Public"
                                  ? "checkbox selected"
                                  : "checkbox"
                              }
                            ></div>
                            <div className="check_label">Public</div>
                          </li>
                          <li
                            className="select_item"
                            onClick={() =>
                              handleFilterChange("unvi_type", "Private")
                            }
                          >
                            <div
                              className={
                                filters?.unvi_type === "Private"
                                  ? "checkbox selected"
                                  : "checkbox"
                              }
                            ></div>
                            <div className="check_label">Private</div>
                          </li>
                        </ul>
                      )}
                    </div>
                    <div className="filter_card">
                      <div
                        className="filter_tit"
                        onClick={() =>
                          setSelectedMenu(
                            selectedMenu === "rating" ? null : "rating"
                          )
                        }
                      >
                        <p> University Rating</p>
                        <div>
                          {selectedMenu === "rating" ? <FaMinus /> : <FaPlus />}
                        </div>
                      </div>
                      {selectedMenu === "rating" ? (
                        <>
                          {/* <span>{ratingcount}</span> */}
                          {ratingcount >= 1 ? (
                            <FaStar
                              className="star-rating bg-star"
                              onClick={() => setRatingCount(1)}
                            />
                          ) : (
                            <FaRegStar
                              className="star-rating"
                              onClick={() => setRatingCount(1)}
                            />
                          )}
                          {ratingcount >= 2 ? (
                            <FaStar
                              className="star-rating bg-star"
                              onClick={() => setRatingCount(2)}
                            />
                          ) : (
                            <FaRegStar
                              className="star-rating"
                              onClick={() => setRatingCount(2)}
                            />
                          )}
                          {ratingcount >= 3 ? (
                            <FaStar
                              className="star-rating bg-star"
                              onClick={() => setRatingCount(3)}
                            />
                          ) : (
                            <FaRegStar
                              className="star-rating"
                              onClick={() => setRatingCount(3)}
                            />
                          )}
                          {ratingcount >= 4 ? (
                            <FaStar
                              className="star-rating bg-star"
                              onClick={() => setRatingCount(4)}
                            />
                          ) : (
                            <FaRegStar
                              className="star-rating"
                              onClick={() => setRatingCount(4)}
                            />
                          )}
                          {ratingcount >= 5 ? (
                            <FaStar
                              className="star-rating bg-star"
                              onClick={() => setRatingCount(5)}
                            />
                          ) : (
                            <FaRegStar
                              className="star-rating"
                              onClick={() => setRatingCount(5)}
                            />
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="filter_card">
                      <div
                        className="filter_tit"
                        onClick={() =>
                          setSelectedMenu(
                            selectedMenu === "budget" ? null : "budget"
                          )
                        }
                      >
                        <p> University Budget</p>
                        <div>
                          {selectedMenu === "budget" ? <FaMinus /> : <FaPlus />}
                        </div>
                      </div>

                      <input
                        type="range"
                        className="cm-wd-100"
                        name="quantity"
                        onChange={(e) => setAmountBudget(e.target.value)}
                        min="50000"
                        max="20000000"
                      />
                      <span className="bedge-primary">
                        {Intl.NumberFormat("en-IN", {
                          maximumSignificantDigits: 3,
                        }).format(amountBudget)}
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="filter_section">
                  <div className="filter_title">
                    <h4>Filters</h4>
                    <p className="clear_all" onClick={() => clearAll()}>
                      Clear All
                    </p>
                    <p className="resp_menu">
                      <IoMdCloseCircleOutline fontSize="20px" />
                    </p>
                  </div>
                  <div className="filterclear">
                    {filters?.course !== "" ? (
                      <span className="filterbtn">{filters?.course}</span>
                    ) : (
                      ""
                    )}
                    {filters?.unvi_type !== "" ? (
                      <span className="filterbtn">{filters?.unvi_type}</span>
                    ) : (
                      ""
                    )}
                    {filters?.country !== "" ? (
                      <span className="filterbtn">{filters?.country}</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="filter_card">
                    <div
                      className="filter_tit"
                      onClick={() =>
                        setSelectedMenu(
                          selectedMenu === "courses" ? null : "courses"
                        )
                      }
                    >
                      <p>Courses</p>
                      <div>
                        {selectedMenu === "courses" ? <FaMinus /> : <FaPlus />}
                      </div>
                    </div>

                    {selectedMenu === "courses" && (
                      <>
                        <ul>
                          {courseData?.data?.map((row, index) => {
                            return (
                              <li
                                className="select_item"
                                onClick={() =>
                                  handleFilterChange("course", row.slug)
                                }
                              >
                                <div
                                  className={
                                    filters?.course === row.name
                                      ? "checkbox selected"
                                      : "checkbox"
                                  }
                                ></div>
                                <div className="check_label">{row.name}</div>
                              </li>
                            );
                          })}
                        </ul>
                        <div className="filter_btn_group">
                          <a href="" className="filter_btn">
                            Diploma
                          </a>
                          <a href="" className="filter_btn">
                            Bachelor
                          </a>
                          <a href="" className="filter_btn">
                            Master
                          </a>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="filter_card">
                    <div
                      className="filter_tit"
                      onClick={() =>
                        setSelectedMenu(
                          selectedMenu === "country" ? null : "country"
                        )
                      }
                    >
                      <p>Country</p>
                      <div>
                        {selectedMenu === "country" ? <FaMinus /> : <FaPlus />}
                      </div>
                    </div>

                    {selectedMenu === "country" && (
                      <ul>
                        {countryData?.data?.map((country) => {
                          return (
                            <li
                              className="select_item"
                              onClick={() =>
                                handleFilterChange("country", country.name)
                              }
                            >
                              <div
                                className={
                                  filters?.country === country.name
                                    ? "checkbox selected"
                                    : "checkbox"
                                }
                              ></div>
                              <div className="check_label">{country.name}</div>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>

                  <div className="filter_card">
                    <div
                      className="filter_tit"
                      onClick={() =>
                        setSelectedMenu(selectedMenu === "type" ? null : "type")
                      }
                    >
                      <p> University Type</p>
                      <div>
                        {selectedMenu === "type" ? <FaMinus /> : <FaPlus />}
                      </div>
                    </div>
                    {selectedMenu === "type" && (
                      <ul>
                        <li
                          className="select_item"
                          onClick={() =>
                            handleFilterChange("unvi_type", "Public")
                          }
                        >
                          <div
                            className={
                              filters?.unvi_type === "Public"
                                ? "checkbox selected"
                                : "checkbox"
                            }
                          ></div>
                          <div className="check_label">Public</div>
                        </li>
                        <li
                          className="select_item"
                          onClick={() =>
                            handleFilterChange("unvi_type", "Private")
                          }
                        >
                          <div
                            className={
                              filters?.unvi_type === "Private"
                                ? "checkbox selected"
                                : "checkbox"
                            }
                          ></div>
                          <div className="check_label">Private</div>
                        </li>
                      </ul>
                    )}
                  </div>
                  <div className="filter_card">
                    <div
                      className="filter_tit"
                      onClick={() =>
                        setSelectedMenu(
                          selectedMenu === "rating" ? null : "rating"
                        )
                      }
                    >
                      <p> University Rating</p>
                      <div>
                        {selectedMenu === "rating" ? <FaMinus /> : <FaPlus />}
                      </div>
                    </div>
                    {selectedMenu === "rating" ? (
                      <>
                        {/* <span>{ratingcount}</span> */}
                        {ratingcount >= 1 ? (
                          <FaStar
                            className="star-rating bg-star"
                            onClick={() => setRatingCount(1)}
                          />
                        ) : (
                          <FaRegStar
                            className="star-rating"
                            onClick={() => setRatingCount(1)}
                          />
                        )}
                        {ratingcount >= 2 ? (
                          <FaStar
                            className="star-rating bg-star"
                            onClick={() => setRatingCount(2)}
                          />
                        ) : (
                          <FaRegStar
                            className="star-rating"
                            onClick={() => setRatingCount(2)}
                          />
                        )}
                        {ratingcount >= 3 ? (
                          <FaStar
                            className="star-rating bg-star"
                            onClick={() => setRatingCount(3)}
                          />
                        ) : (
                          <FaRegStar
                            className="star-rating"
                            onClick={() => setRatingCount(3)}
                          />
                        )}
                        {ratingcount >= 4 ? (
                          <FaStar
                            className="star-rating bg-star"
                            onClick={() => setRatingCount(4)}
                          />
                        ) : (
                          <FaRegStar
                            className="star-rating"
                            onClick={() => setRatingCount(4)}
                          />
                        )}
                        {ratingcount >= 5 ? (
                          <FaStar
                            className="star-rating bg-star"
                            onClick={() => setRatingCount(5)}
                          />
                        ) : (
                          <FaRegStar
                            className="star-rating"
                            onClick={() => setRatingCount(5)}
                          />
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="filter_card">
                    <div
                      className="filter_tit"
                      onClick={() =>
                        setSelectedMenu(
                          selectedMenu === "budget" ? null : "budget"
                        )
                      }
                    >
                      <p> University Budget</p>
                      <div>
                        {selectedMenu === "budget" ? <FaMinus /> : <FaPlus />}
                      </div>
                    </div>

                    <input
                      type="range"
                      className="cm-wd-100"
                      name="quantity"
                      onChange={(e) => setAmountBudget(e.target.value)}
                      min="50000"
                      max="20000000"
                    />
                    <span className="bedge-primary">
                      {Intl.NumberFormat("en-IN", {
                        maximumSignificantDigits: 3,
                      }).format(amountBudget)}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="study_rgt">
              <div className="search_container">
                <div className="search_wrapper">
                  <div className="resp_none"></div>
                  <div className="search_box">
                    <input
                      type="text"
                      name=""
                      id=""
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder="Search by Country"
                    />
                  </div>
                  <div
                    className="filter_btn"
                    onClick={() => setOpenModal(true)}
                  >
                    <CiFilter /> Search
                  </div>
                </div>
              </div>

              <div className="university_section">
                {isLoading ? "...loading" : ""}
                {universityData?.pages.map((page) =>
                  page.map((row, i) => (
                    <div className="university_card">
                      <div className="card_wrapper">
                        <div className="university_image">
                          <img
                            src={row?.feature_image}
                            alt=""
                            width="100%"
                          />

                          <div className="uni_overlay">
                            <span>{row?.university_type}</span>
                          </div>
                        </div>
                        <div className="university_detail">
                          <div className="univesity_title">
                            <h3>
                              <Link to={`/university/${row?.slug}`}>
                                {row.title}
                              </Link>
                            </h3>
                            <div className="uni_subtitle">
                              <div className="uni_course">
                                <span className="univer_resp">
                                  {" "}
                                  Course : {row?.course_id}
                                </span>
                              </div>
                              <div>|</div>
                              <div className="uni_degree">
                                <span className="univer_resp">
                                  {" "}
                                  Degree: {row?.degree}
                                </span>
                              </div>
                              <div>|</div>
                              <div className="uni_country">
                                <span className="univer_resp">
                                  {" "}
                                  Country: {row?.country}
                                </span>
                              </div>
                            </div>
                            <div className="spe_wrap">
                              <div className="spe_title">Loan </div>
                              <div className="spec_ans">: Available</div>
                            </div>
                          </div>
                          <div className="university_specification">
                            <div className="univer_spec_left">
                              <div className="spe_wrap">
                                <div className="spe_title">University Type</div>
                                <div className="spec_ans">
                                  : {row?.university_type}
                                </div>
                              </div>
                              <div className="spe_wrap">
                                <div className="spe_title">Univ Grade </div>
                                <div className="spec_ans">
                                  : {row?.university_grade}
                                </div>
                              </div>
                              <div className="spe_wrap">
                                <div className="spe_title">Univ Rank </div>
                                <div className="spec_ans">
                                  : {row?.world_rank}
                                </div>
                              </div>
                            </div>
                            <div className="univer_spec_right">
                              <div className="spe_wrap">
                                <div className="spe_title">Intake</div>
                                <div className="spec_ans">: {row?.intake}</div>
                              </div>
                              <div className="spe_wrap">
                                <div className="spe_title">Duration </div>
                                <div className="spec_ans">
                                  : {row?.duration}
                                </div>
                              </div>
                              <div className="spe_wrap">
                                <div className="spe_title">Fee </div>
                                <div className="spec_ans">: {row?.fee}</div>
                              </div>
                            </div>
                          </div>

                          <p className="blink">
                            Admission Helpline : <span>+91 8284972098</span>
                          </p>
                        </div>
                        <div className="university_sub_detail">
                          <div className="university_btn_grp">
                            <a
                              className="download_btn"
                              onClick={() => handleDownloadBrochure(row?.brochure, row?.title)}
                              // download={row?.brochure}
                            >
                              Brouchure
                            </a>

                            <a
                              className="apply_now_btn"
                              onClick={() => handleApplyNow(row?.title)}
                            >
                              Apply Now
                            </a>
                            <Link
                              to={`/university/${row?.slug}`}
                              className="view_btn"
                            >
                              View Detail
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
                {hasNextPage ? (
                  <button
                    disabled={isFetchingNextPage}
                    className="text-center apply_btn"
                    onClick={() => fetchNextPage()}
                  >
                    Load More
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* modal */}

      {showModal && (
        <section className="modal_wrapper">
          <div className="modal_container">
            <div className="modal_title">
              <h2>Download Brouchre</h2>
            </div>

            <button className="close_btn" onClick={() => setShowModal(false)}>
              <IoMdCloseCircleOutline fontSize="25px" />
            </button>
            <div className="my_modal">
              <div className="modal_thumb">
                <img src={brochure} alt="" style={{ width: "100%" }} />
              </div>
              <div className="modal_desc">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-content">
                    <input type="text" name="name" {...register("name", { required: true })} placeholder="Enter Your Name" />
                    {errors.name && <p>This field is required</p>}
                  </div>
                  <div className="form-content">
                    <input type="email"  name="email" {...register("email", { required: true })} placeholder="Enter Your Email" />
                    {errors.email && <p>This email is required</p>}
                  </div>
                  <div className="form-content">
                    <input type="number" name="number" {...register("number", { required: true })} placeholder="Enter Whatsapp Number" />
                    {errors.number && <p>This number is required</p>}
                  </div>
                  <div className="form-content">
                    <input type="submit" value="Submit" className="apply_btn" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}

      {applyModal && (
        <section className="modal_wrapper">
          <div className="modal_container">
            <div className="modal_title">
              <h2>Apply And Book Your University</h2>
            </div>

            <button className="close_btn" onClick={() => setApplyModal(false)}>
              <IoMdCloseCircleOutline fontSize="25px" />
            </button>
            <div className="my_modal">
              <div className="modal_thumb">
                <img src={apply} alt="" style={{ width: "100%" }} />
              </div>
              <div className="modal_desc">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-content">
                    <input type="text" name="name" {...register("name", { required: true })} placeholder="Enter Full Name" />
                    {errors.name && <p>This field is required</p>}
                  </div>
                  <div className="form-content">
                    <input type="number" name="number" {...register("number", { required: true })} placeholder="Enter Whatsapp Number" />
                    {errors.number && <p>This number is required</p>}
                  </div>
                  <button className="apply_btn">Submit</button>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default StudyAbroad;

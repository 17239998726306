import React, { useState } from "react";
import "../assets/styles/Home.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import newrespimg from "../assets/images/banner/newrespimg.jpg";
import newrespimgn from "../assets/images/banner/newrespimgn.jpg";
import newRespSlider_2 from "../assets/images/banner/newRespSlider_2.jpg";
import mbbsInIndRes from "../assets/images/banner/mbbsInIndRes.jpg";
import mbbsInIndia from "../assets/images/banner/mbbsInIndia.jpg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import { Navigation } from "swiper/modules";

import { Autoplay, Pagination } from "swiper/modules";
import { CiSearch } from "react-icons/ci";
import india from "../assets/images/fields/india.jpg";
import mbbs from "../assets/images/fields/mbbs.jpg";
import abroad from "../assets/images/fields/abroad.jpg";
import { FaArrowRightLong, FaPlay } from "react-icons/fa6";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import eng from "../assets/images/icons/eng.png";
import law from "../assets/images/icons/law.png";
import manag from "../assets/images/icons/manag.png";
import medical from "../assets/images/icons/medical.png";
import teach from "../assets/images/icons/teach.png";
import "../assets/styles/swiper.css";
import desk_ban from "../assets/images/banner/desk_ban.jpg";

import desk_ban_n from "../assets/images/banner/desk_ban_n.jpg";

import "../assets/images/banner/mobileBanner.jpg";
import test from "../assets/images/icons/test.png";
import course from "../assets/images/icons/course.png";
import career from "../assets/images/icons/career.png";
import visa from "../assets/images/icons/visa.png";
import admission from "../assets/images/icons/admission.png";
import business from "../assets/images/logo/business.webp";
import ndtv from "../assets/images/logo/ndtv.webp";
import { FaHeadphonesAlt, FaSearch } from "react-icons/fa";
import { ImMail } from "react-icons/im";
import w1 from "../assets/images/banner/w1.jpg";
import w2 from "../assets/images/banner/w2.jpg";
import w3 from "../assets/images/banner/w3.jpg";
import fUser from "../assets/images/clients/fUser.jpg";
import sUser from "../assets/images/clients/sUser.jpg";
import tUser from "../assets/images/clients/tUser.jpg";
import eUser from "../assets/images/clients/eUser.jpg";
import webBannern from "../assets/images/banner/webBannern.jpg";
import mbbsbannern from "../assets/images/banner/mbbsbannern.jpg";
// import image from home page
import blinkNew from "../assets/images/home/new_icon_blink.gif";
import abroadNotice from "../assets/images/notice/abroadNotice.jpg";
import indiaNotice from "../assets/images/notice/indiaNotice.jpg";
import mbbsNotice from "../assets/images/notice/mbbsNotice.jpg";
import axios from "axios";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const Home = () => {
  const fields = [
    {
      img: mbbs,
      caption: {
        head: "MBBS in Abroad",
        sub: "Same as an Indian Budget",
      },
      desc: "Empowering students with personalized guidance and global opportunities, our consultancy transforms aspirations into achievements by providing comprehensive educational support and access to world-renowned institutions.",
    },
    {
      img: india,
      caption: {
        head: "Study In India",
        sub: "In top Universities",
      },
      desc: "With a dedicated team of experts, we navigate the complexities of educational pathways, ensuring every student finds their perfect fit and excels in their academic journey, from admissions to career success",
    },

    {
      img: abroad,
      caption: {
        head: "Study in Abroad",
        sub: "In top Universities",
      },
      desc: "Committed to excellence, our consultancy offers unparalleled resources and tailored strategies, helping students realize their potential and succeed in prestigious academic environments across the globe.",
    },
  ];

  const courses = [
    {
      img: medical,
      title: "Medical",
    },
    {
      img: eng,
      title: "Engineering",
    },
    {
      img: manag,
      title: "Management",
    },
    {
      img: law,
      title: "Law",
    },
    {
      img: teach,
      title: "Teaching",
    },
  ];

  const isMobile = window.innerWidth <= 768;

  const [file, setFile] = useState(null);
  const [tabslider, setTabSlider] = useState("Notification");
  // Mbbs Aborad
  const notificationFun = async () => {
    let url = process.env.REACT_APP_BASE_URL;
    let response = await axios.get(url + `notification?category=${tabslider}`);
    return response?.data;
  };

  const { data: notification, isLoading: loadnotice } = useQuery({
    queryKey: ["getNotification", tabslider],
    queryFn: () => notificationFun(),
  });

  // Search Unvierstiy

  const allseachpara = async () => {
    let url = process.env.REACT_APP_BASE_URL;
    let response = await axios.get(url + `searchuniversity`);
    return response?.data;
  };

  const { data: searchuniversity } = useQuery({
    queryKey: ["allsearchpara"],
    queryFn: () => allseachpara(),
  });

  // console.log("Search", searchuniversity);
  const navigate = useNavigate();

  const searchfiled = (e) => {
    e.preventDefault();

    navigate(`/study-abroad/${e.target.country.value}`);
    console.log("value of search", e.target.course.value);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home | Ims Education Global</title>
        <meta name="description" content="ims education" />
        <link rel="canonical" href="https://imseduglobal.com" />
      </Helmet>
      <section className="main_wrapper">
        <div className="desktop_banner">
          <Swiper
            pagination={true}
            autoplay={{
              delay: 2500,
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img
                src={mbbsbannern}
                alt=""
                style={{ height: "auto", width: "100%" }}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={webBannern}
                alt=""
                style={{ height: "auto", width: "100%" }}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={mbbsInIndia}
                alt=""
                style={{ height: "auto", width: "100%" }}
              />
            </SwiperSlide>
          </Swiper>
        </div>

        <div className="mobile_banner">
          <Swiper
            pagination={true}
            autoplay={{
              delay: 2500,
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img
                src={newrespimg}
                alt=""
                style={{ height: "500px", width: "100%" }}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={newrespimgn}
                alt=""
                style={{ height: "500px", width: "100%" }}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={mbbsInIndRes}
                alt=""
                style={{ height: "500px", width: "100%" }}
              />
            </SwiperSlide>
          </Swiper>
        </div>

        <div className="enquiry_form">
          <h4 style={{ textAlign: "center" }}>Search Your University</h4>
          <form onSubmit={searchfiled}>
            <div className="form-content">
              <select name="course">
                <option value="">Select Course</option>
                {searchuniversity?.course?.map((row) => (
                  <option value={row?.name}>{row?.name}</option>
                ))}
              </select>
            </div>
            <div className="form-content">
              <select name="degree">
                <option value="">Select Degree</option>
                {searchuniversity?.degree?.map((row) => (
                  <option value={row?.name}>{row?.name}</option>
                ))}
              </select>
            </div>
            <div className="form-content">
              <select name="country" required>
                <option value="">Select Country</option>
                {searchuniversity?.country?.map((row) => (
                  <option value={row?.name}>{row?.name}</option>
                ))}
              </select>
            </div>
            <input type="submit" className="apply_btn" value="Search" />
          </form>
        </div>
      </section>

      {/* Top Highlights */}
      <section
        className="pt-70 pb-70 field_section"
        style={{ background: "#fff" }}
      >
        <div className="container">
          <div className="cm-flex-type-0 cm-flex-wrap cm-flex-align-fs">
            <div className="cm-wd-60 rounded-10 cm-mob-full cm-mb-20">
              <Swiper
                navigation={true}
                modules={[Navigation]}
                className="mySwiper  box-shadow-1 rounded-10"
              >
                <SwiperSlide>
                  <img
                    src={abroadNotice}
                    alt=""
                    className="slider-image-notification"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={indiaNotice}
                    alt=""
                    className="slider-image-notification"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={mbbsNotice}
                    alt=""
                    className="slider-image-notification"
                  />
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="cm-wd-38 cm-mob-full">
              <div className="notification-tab box-shadow-1">
                <ul className="nav nav-tabs">
                  <li
                    className={`${
                      tabslider === "Notification" ? `active` : ``
                    }`}
                    onClick={() => setTabSlider("Notification")}
                  >
                    <a>
                      <strong> Notification</strong>
                    </a>{" "}
                  </li>
                  <li
                    className={`${tabslider === "New Courses" ? `active` : ``}`}
                    onClick={() => setTabSlider("New Courses")}
                  >
                    <a>
                      <strong> New Courses</strong>
                    </a>{" "}
                  </li>
                  <li
                    className={`${tabslider === "Results" ? `active` : ``}`}
                    onClick={() => setTabSlider("Results")}
                  >
                    <a>
                      <strong>Results</strong> <img src={blinkNew} />
                    </a>{" "}
                  </li>
                </ul>
                <div className="">
                  <div className="notification-box">
                    <div
                      className={`${
                        tabslider === "Notification" ? `active` : `inactive`
                      }`}
                    >
                      <ul className="notificatoinlistunder">
                        {notification?.data?.map((row) => (
                          <li className="notification">
                            <MdKeyboardDoubleArrowRight />
                            <div className="overflow-h">
                              <span>
                                <strong>
                                  <a href="#" target="_blank">
                                    <p>{row?.subject}</p>
                                    <img
                                      src={blinkNew}
                                      alt="Strategy by BM Sir"
                                      className="mCS_img_loaded"
                                    />
                                  </a>
                                </strong>
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div
                      className={`${
                        tabslider === "New Courses" ? `active` : `inactive`
                      }`}
                    >
                      <ul className="notificatoinlistunder">
                        {notification?.data?.map((row) => (
                          <li className="notification">
                            <MdKeyboardDoubleArrowRight />
                            <div className="overflow-h">
                              <span>
                                <strong>
                                  <a href="#" target="_blank">
                                    <p>{row?.subject}</p>
                                    <img
                                      src={blinkNew}
                                      alt="Strategy by BM Sir"
                                      className="mCS_img_loaded"
                                    />
                                  </a>
                                </strong>
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div
                      className={`${
                        tabslider === "Results" ? `active` : `inactive`
                      }`}
                    >
                      <ul className="notificatoinlistunder">
                        {notification?.data?.map((row) => (
                          <li className="notification">
                            <MdKeyboardDoubleArrowRight />
                            <div className="overflow-h">
                              <span>
                                <strong>
                                  <a href="#" target="_blank">
                                    <p>{row?.subject}</p>
                                    <img
                                      src={blinkNew}
                                      alt="Strategy by BM Sir"
                                      className="mCS_img_loaded"
                                    />
                                  </a>
                                </strong>
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Top Highlights */}
      <section className="pb-70 field_section">
        <div className="container">
          <div className="header_content">
            <h2>Our Highlights</h2>
          </div>
          <div className="fields_items">
            {fields.map((field) => {
              return (
                <div className="field_item">
                  <div className="image_card">
                    <img src={field.img} alt="" />
                  </div>

                  <div className="fs-content">{field.desc}</div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="counceller">
        <div className="container">
          <div className="counceller_card">
            <div className="councel_wrapper">
              <div className="councel_tit">
                <h3>Want to get a free consultation</h3>
              </div>
              <div className="councel_add">
                <div className="councel_add_item">
                  <FaHeadphonesAlt fontSize="30px" />
                  <p>+91 8284972098</p>
                </div>
                <div className="councel_add_item">
                  <ImMail fontSize="30px" />
                  <p>info@imseducation.in</p>
                </div>
              </div>
              <div className="councel_btn">
                <a href="" className="apply_btn">
                  Talk to our Counceller
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* courses */}

      <section className="pt-70 pb-70 courses_sec">
        <div className="container">
          <div className="header_content">
            <h2>Popular Courses</h2>
          </div>
          <div className="courses_wrapper">
            {courses.map((course) => {
              return (
                <>
                  <div className="course_item">
                    <div className="course_card">
                      <img src={course.img} alt="" />
                      <h4>{course.title}</h4>
                      <p>
                        <FaArrowRightLong />
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>

      {/* university search */}

      <section className="university_search pt-70 pb-70">
        <div className="container">
          <div className="search_form">
            <div className="header_content">
              <h2>
                Quick <span>University Search</span>
              </h2>
            </div>
            <div className="form_wrapper">
              <div className="search_form_item">
                <select name="course">
                  <option value="">Select Course</option>
                  {searchuniversity?.course?.map((row) => (
                    <option value={row?.name}>{row?.name}</option>
                  ))}
                  <option value="management">Management</option>
                </select>
              </div>
              <div className="search_form_item">
                <select name="degree">
                  <option value="">Select Degree</option>
                  {searchuniversity?.degree?.map((row) => (
                    <option value={row?.name}>{row?.name}</option>
                  ))}
                </select>
              </div>
              <div className="search_form_item">
                <select name="country">
                  <option value="">Select Country</option>
                  {searchuniversity?.country?.map((row) => (
                    <option value={row?.name}>{row?.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="search_btn_grp">
              <a className="apply_btn">
                <CiSearch fontSize="10px" />
                <p>Search</p>
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* career Stories */}
      <section className="stories pt-70 pb-70">
        <div className="container">
          <div className="header_content">
            <h2>Students Stories</h2>
          </div>
          <div className="story_container_section">
            <div className="video-col-1">
              <div className="video-wrap">
                <div className="video">
                  <div className="video-thumb ratio1">
                    <img
                      src={fUser}
                      width="514"
                      height="400"
                      alt="mauritian patient treated for esophageal cancer"
                    />
                    <div
                      onClick={() =>
                        setFile(
                          "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                        )
                      }
                      className="play-video"
                    >
                      <img
                        src="https://www.medanta.org/assets/images/icons/play-icon.png"
                        alt=""
                        width="48"
                        height="48"
                      />
                    </div>
                  </div>
                  <div className="video-caption">
                    <div className="video-title">
                      Mauritian Patient Treated For Esophageal Cancer
                    </div>
                    <p>Mr Fazl Hosany</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="video-col-2">
              <div className="video-wrap">
                <div className="video">
                  <div className="video-thumb ratio2">
                    <img
                      src={sUser}
                      width="514"
                      height="400"
                      alt="liver failure"
                    />

                    <div
                      onClick={() =>
                        setFile(
                          "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                        )
                      }
                      className="play-video"
                    >
                      <img
                        src="https://www.medanta.org/assets/images/icons/play-icon.png"
                        alt="play-icon"
                        width="48"
                        height="48"
                      />
                    </div>
                  </div>
                  <div className="video-caption">
                    <div className="video-title">Liver Failure</div>
                    <p>Baby Bhavika</p>
                  </div>
                </div>
              </div>
              <div className="video-wrap">
                <div className="video">
                  <div className="video-thumb ratio2">
                    <img
                      src={tUser}
                      alt="jaw cancer"
                      width="514"
                      height="400"
                    />

                    <div
                      onClick={() =>
                        setFile(
                          "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                        )
                      }
                      className="play-video"
                    >
                      <img
                        src="https://www.medanta.org/assets/images/icons/play-icon.png"
                        width="48"
                        height="48"
                        alt="play-icon"
                      />
                    </div>
                  </div>
                  <div className="video-caption">
                    <div className="video-title">Jaw Cancer</div>
                    <p>Dr. Abhilasha Agarwal </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="video-col-2">
              <div className="video-wrap">
                <div className="video">
                  <div className="video-thumb ratio2">
                    <img
                      src={eUser}
                      width="514"
                      height="400"
                      alt="liver failure"
                    />

                    <div
                      onClick={() =>
                        setFile(
                          "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                        )
                      }
                      className="play-video"
                    >
                      <img
                        src="https://www.medanta.org/assets/images/icons/play-icon.png"
                        alt="play-icon"
                        width="48"
                        height="48"
                      />
                    </div>
                  </div>
                  <div className="video-caption">
                    <div className="video-title">Liver Failure</div>
                    <p>Baby Bhavika</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="video-col-2">
              <div className="video-wrap">
                <div className="video">
                  <div className="video-thumb ratio2">
                    <img
                      src={sUser}
                      width="514"
                      height="400"
                      alt="liver failure"
                    />

                    <div
                      onClick={() =>
                        setFile(
                          "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                        )
                      }
                      className="play-video"
                    >
                      <img
                        src="https://www.medanta.org/assets/images/icons/play-icon.png"
                        alt="play-icon"
                        width="48"
                        height="48"
                      />
                    </div>
                  </div>
                  <div className="video-caption">
                    <div className="video-title">Liver Failure</div>
                    <p>Baby Bhavika</p>
                  </div>
                </div>
              </div>
              <div className="video-wrap">
                <div className="video">
                  <div className="video-thumb ratio2">
                    <img
                      src={tUser}
                      alt="jaw cancer"
                      width="514"
                      height="400"
                    />

                    <div
                      onClick={() =>
                        setFile(
                          "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                        )
                      }
                      className="play-video"
                    >
                      <img
                        src="https://www.medanta.org/assets/images/icons/play-icon.png"
                        width="48"
                        height="48"
                        alt="play-icon"
                      />
                    </div>
                  </div>
                  <div className="video-caption">
                    <div className="video-title">Jaw Cancer</div>
                    <p>Dr. Abhilasha Agarwal </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="video-col-1">
              {" "}
              <div className="video-wrap">
                <div className="video">
                  <div className="video-thumb ratio1">
                    <img
                      src={fUser}
                      width="514"
                      height="400"
                      alt="mauritian patient treated for esophageal cancer"
                    />
                    <div
                      onClick={() =>
                        setFile(
                          "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                        )
                      }
                      className="play-video"
                    >
                      <img
                        src="https://www.medanta.org/assets/images/icons/play-icon.png"
                        alt=""
                        width="48"
                        height="48"
                      />
                    </div>
                  </div>
                  <div className="video-caption">
                    <div className="video-title">
                      Mauritian Patient Treated For Esophageal Cancer
                    </div>
                    <p>Mr Fazl Hosany</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="resp_story_container">
            <Swiper
              pagination={true}
              autoplay={{
                delay: 2500,
              }}
              modules={[Autoplay, Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="video-col-1">
                  <div className="video-wrap">
                    <div className="video">
                      <div className="video-thumb ratio1">
                        <img
                          src={fUser}
                          alt="mauritian patient treated for esophageal cancer"
                        />
                        <div
                          onClick={() =>
                            setFile(
                              "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                            )
                          }
                          className="play-video"
                        >
                          <img
                            src="https://www.medanta.org/assets/images/icons/play-icon.png"
                            alt=""
                            width="48"
                            height="48"
                          />
                        </div>
                      </div>
                      <div className="video-caption">
                        <div className="video-title">Mauritian</div>
                        <p>Mr Fazl Hosany</p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="video-col-2">
                  <div className="video-wrap">
                    <div className="video">
                      <div className="video-thumb ratio2">
                        <img
                          src={sUser}
                          width="514"
                          height="400"
                          alt="liver failure"
                        />

                        <div
                          onClick={() =>
                            setFile(
                              "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                            )
                          }
                          className="play-video"
                        >
                          <img
                            src="https://www.medanta.org/assets/images/icons/play-icon.png"
                            alt="play-icon"
                            width="48"
                            height="48"
                          />
                        </div>
                      </div>
                      <div className="video-caption">
                        <div className="video-title">Liver Failure</div>
                        <p>Baby Bhavika</p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="video-col-2">
                  <div className="video-wrap">
                    <div className="video">
                      <div className="video-thumb ratio2">
                        <img
                          src={eUser}
                          width="514"
                          height="400"
                          alt="liver failure"
                        />

                        <div
                          onClick={() =>
                            setFile(
                              "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                            )
                          }
                          className="play-video"
                        >
                          <img
                            src="https://www.medanta.org/assets/images/icons/play-icon.png"
                            alt="play-icon"
                            width="48"
                            height="48"
                          />
                        </div>
                      </div>
                      <div className="video-caption">
                        <div className="video-title">Liver Failure</div>
                        <p>Baby Bhavika</p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="video-col-2">
                  <div className="video-wrap">
                    <div className="video">
                      <div className="video-thumb ratio2">
                        <img
                          src={sUser}
                          width="514"
                          height="400"
                          alt="liver failure"
                        />

                        <div
                          onClick={() =>
                            setFile(
                              "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                            )
                          }
                          className="play-video"
                        >
                          <img
                            src="https://www.medanta.org/assets/images/icons/play-icon.png"
                            alt="play-icon"
                            width="48"
                            height="48"
                          />
                        </div>
                      </div>
                      <div className="video-caption">
                        <div className="video-title">Liver Failure</div>
                        <p>Baby Bhavika</p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="video-col-2">
                  <div className="video-wrap">
                    <div className="video">
                      <div className="video-thumb ratio2">
                        <img
                          src={tUser}
                          alt="jaw cancer"
                          width="514"
                          height="400"
                        />

                        <div
                          onClick={() =>
                            setFile(
                              "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                            )
                          }
                          className="play-video"
                        >
                          <img
                            src="https://www.medanta.org/assets/images/icons/play-icon.png"
                            width="48"
                            height="48"
                            alt="play-icon"
                          />
                        </div>
                      </div>
                      <div className="video-caption">
                        <div className="video-title">Jaw Cancer</div>
                        <p>Dr. Abhilasha Agarwal </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="video-col-1">
                  <div className="video-wrap">
                    <div className="video">
                      <div className="video-thumb ratio1">
                        <img
                          src={fUser}
                          width="514"
                          height="400"
                          alt="mauritian patient treated for esophageal cancer"
                        />
                        <div
                          onClick={() =>
                            setFile(
                              "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                            )
                          }
                          className="play-video"
                        >
                          <img
                            src="https://www.medanta.org/assets/images/icons/play-icon.png"
                            alt=""
                            width="48"
                            height="48"
                          />
                        </div>
                      </div>
                      <div className="video-caption">
                        <div className="video-title">
                          Mauritian Patient Treated For Esophageal Cancer
                        </div>
                        <p>Mr Fazl Hosany</p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>

          <div
            className="popup-media"
            style={{ display: file ? "block" : "none" }}
          >
            <span onClick={() => setFile(null)}>&times;</span>
            {file && (
              <iframe
                width="500px"
                height="315"
                src={file}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            )}
          </div>
        </div>
      </section>

      {/* our Success  */}

      <section className="success_card pt-70 pb-70">
        <div className="container">
          <div className="success_card_wrapper">
            <div className="success_left">
              <div className="mb-20">
                <h1>Success for the last 10 years</h1>
                <h2>Study Abroad Consultant</h2>
              </div>

              <p>
                Over the past 10 years, IMS Education has transformed dreams
                into reality for countless students. Our experienced consultants
                boast an impressive success rate, guiding students to top
                universities worldwide through unparalleled expertise, a global
                network, and a dedication to highlighting your strengths.
                Partner with IMS Education and unlock your potential for a
                life-changing study abroad experience.
              </p>
            </div>
            <div className="success_right">
              <div className="counter_cards">
                <div className="counter_item">
                  <h2>
                    <span>300</span>+
                  </h2>

                  <p>Total Courses</p>
                </div>
                <div className="counter_item">
                  <h2>
                    <span>100</span>+
                  </h2>

                  <p>Universities</p>
                </div>
                <div className="counter_item">
                  <h2>
                    <span>1500</span>+
                  </h2>

                  <p>Student Servied</p>
                </div>
                <div className="counter_item">
                  <h2>
                    <span>90</span>%
                  </h2>

                  <p>Visa Success Ratio</p>
                </div>

                <div className="counter_item">
                  <h2>
                    <span>5</span>+
                  </h2>

                  <p>Serving Since</p>
                </div>
                <div className="counter_item">
                  <h2>
                    <span>60</span>%
                  </h2>

                  <p>Student Councelled</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* our services */}

      <section className="service pt-70 pb-70">
        <div className="container">
          <div className="header_content">
            <h2>Our Reliable Services</h2>
          </div>

          <div className="reliable_service_wrapper">
            <div className="service_item">
              <img src={test} alt="" />
              <p>Test Preparation</p>
            </div>
            <div className="service_item">
              <img src={admission} alt="" />
              <p>Admission Guidance</p>
            </div>
            <div className="service_item">
              <img src={course} alt="" />
              <p>Course Selection</p>
            </div>
            <div className="service_item">
              <img src={career} alt="" />
              <p>Career Counselling</p>
            </div>
            <div className="service_item">
              <img src={visa} alt="" />
              <p>Visa and Travel Assistance</p>
            </div>
          </div>
        </div>
      </section>

      {/* webinar */}
      <section className="webinar pt-70 pb-70">
        <div className="container">
          <div className="header_content">
            <h2>Upcoming Webinar</h2>
          </div>

          <div className="webinar_wrapper">
            <div className="webinar_item">
              <div className="webinar_card">
                <div className="webinar_thumb">
                  <img src={w1} alt="" style={{ width: "100%" }} />
                </div>
                <div className="webinar_content">
                  <div className="event_time">
                    <div className="event_day">
                      <h4>25</h4>
                      <h5>April</h5>
                    </div>
                    <div className="event_content">
                      <strong>Saturday 6.00 pm - 7.00 pm</strong>
                    </div>
                  </div>
                  <p>
                    Learn about the diverse opportunities and advantages of
                    studying MBBS in Russia, including hands-on clinical
                    experience, extensive research opportunities, and vibrant
                    student life.
                  </p>

                  {/* <div> */}
                  <a href="" className="solid_border_btn">
                    Register Now
                  </a>
                  {/* </div> */}
                </div>
              </div>
            </div>
            <div className="webinar_item">
              <div className="webinar_card">
                <div className="webinar_thumb">
                  <img src={w2} alt="" style={{ width: "100%" }} />
                </div>
                <div className="webinar_content">
                  <div className="event_time">
                    <div className="event_day">
                      <h4>25</h4>
                      <h5>April</h5>
                    </div>
                    <div className="event_content">
                      <strong>Saturday 6.00 pm - 7.00 pm</strong>
                    </div>
                  </div>
                  <p>
                    Find out why Nepal is becoming a popular destination for
                    medical education, with internationally accredited programs,
                    experienced faculty, and diverse clinical settings.
                  </p>

                  {/* <div> */}
                  <a href="" className="solid_border_btn">
                    Register Now
                  </a>
                  {/* </div> */}
                </div>
              </div>
            </div>
            <div className="webinar_item">
              <div className="webinar_card">
                <div className="webinar_thumb">
                  <img src={w3} alt="" style={{ width: "100%" }} />
                </div>
                <div className="webinar_content">
                  <div className="event_time">
                    <div className="event_day">
                      <h4>25</h4>
                      <h5>April</h5>
                    </div>
                    <div className="event_content">
                      <strong>Saturday 6.00 pm - 7.00 pm</strong>
                    </div>
                  </div>
                  <p>
                    Learn about the comprehensive medical training offered in
                    India, combining theoretical knowledge with hands-on
                    experience in some of the best hospitals and research
                    centers in the country.
                  </p>

                  {/* <div> */}
                  <a href="" className="solid_border_btn">
                    Register Now
                  </a>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Media Coverage */}
      <section className="coverage pt-70 pb-150">
        <div className="container">
          <div className="header_content">
            <h2>Media Coverage</h2>
          </div>

          <div className="coverage_wrapper">
            <div className="media_item">
              <img src={ndtv} alt="" />
            </div>
            <div className="media_item">
              <img src={business} alt="" />
            </div>
            <div className="media_item">
              <img src={ndtv} alt="" />
            </div>
            <div className="media_item">
              <img src={business} alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="contact_sec">
        <div className="container">
          <div className="yellow_box">
            <div className="yellow_box_wrapper">
              <div className="yellow_left">
                <div className="get_call">
                  <div>
                    <FaHeadphonesAlt color="#000" fontSize="50px" />
                  </div>
                  <div className="call_no">Get Call Back From Our Experts</div>
                </div>
              </div>
              <div className="yellow_right">
                <form action="">
                  <div className="form_wrapper">
                    <input
                      type="text"
                      placeholder="Search"
                      className="contact_input"
                    />
                    <a href="" className="contact_btn">
                      <FaSearch />
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;

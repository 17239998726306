import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { LuShare2 } from "react-icons/lu";
import "../assets/styles/Mbbs.css";
import Moment from 'react-moment';
import {
  FaFacebook,
  FaLinkedin,
  // FaRegCircleRight,
  // FaSquare,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa6";
import RUSSIA from "../assets/images/flags/RUSSIA.png";
import BANGLADESH from "../assets/images/flags/BANGLADESH.png";
import Belize from "../assets/images/flags/Belize.png";
import China from "../assets/images/flags/China.png";
import nepal from "../assets/images/flags/nepal.png";
import Egypt from "../assets/images/flags/Egypt.png";
import Georgia from "../assets/images/flags/Georgia.png";
import Khazakhstan from "../assets/images/flags/Khazakhstan.png";
import Kyrgyzstan from "../assets/images/flags/Kyrgyzstan.png";
import Philippins from "../assets/images/flags/Philippins.png";
import Romania from "../assets/images/flags/Romania.png";
import { FaInstagramSquare } from "react-icons/fa";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { IoMdCloseCircleOutline } from "react-icons/io";
import apply from "../assets/images/modal/apply.jpg";
import brochure from "../assets/images/modal/brochure.jpg";
import axios from "axios";
import { useQuery } from "react-query";
import useEnquiry from "../useHooks/useEnquiry";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";
import Loader from "../components/Loader";

const BlogDetail = () => {
  const { slug } = useParams();
  const [flag, setCountryFlag] = useState("");
  const [selected, setSelected] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [brochureModal, setBrochureModal] = useState(false);
  const [applyModal, setApplyModal] = useState(false);
  const [brocherDowload, setBrocherDowload] = useState("");
  const [filterUnivercities, setFilterUnivesities] = useState([]);
  const { register, setValue, handleSubmit, formState:{errors},reset } = useForm();
  const {mutateAsync} = useEnquiry();
  const getBlogDetial = async () => {
    const newurl = process.env.REACT_APP_BASE_URL + `blog-detail/` + slug;
    const { data } = await axios.get(newurl);
    return data;
  };

  const { data, isLoading } = useQuery({
    queryKey: ["getBlogDetail", slug],
    queryFn: () => getBlogDetial(),
  });
  // const {data:exploreData} = useQuery({queryKey : ['exploreBlog'], queryFn: () => exploreBlog()});

  const Universities = [
    {
      name: "Yashpal",
      country: "russia",
      course: "Medical",
      type: "Public",
    },
    {
      name: "Tridev",
      country: "china",
      course: "Engineering",
      type: "Public",
    },
    {
      name: "sofard",
      country: "bangladesh",
      course: "Law",
      type: "Public",
    },
    {
      name: "roxfard",
      country: "china",
      course: "Medical",
      type: "Private",
    },
    {
      name: "Siyue",
      country: "russia",
      course: "Teaching",
      type: "Private",
    },
    {
      name: "kiwtre",
      country: "russia",
      course: "Medical",
      type: "Private",
    },
  ];

  const countries = [
    {
      id: "nepal",
      img: nepal,
    },
    {
      id: "bangladesh",
      img: BANGLADESH,
    },
    {
      id: "china",
      img: China,
    },
    {
      id: "russia",
      img: RUSSIA,
    },
    {
      id: "philippins",
      img: Philippins,
    },
    {
      id: "khazakhstan",
      img: Khazakhstan,
    },
    {
      id: "kyrgyzstan",
      img: Kyrgyzstan,
    },
    {
      id: "georgia",
      img: Georgia,
    },

    {
      id: "egypt",
      img: Egypt,
    },
    {
      id: "romania",
      img: Romania,
    },
    {
      id: "belize",
      img: Belize,
    },
  ];

  const faqs = [
    {
      question: "What is orthodontics?",
      answer:
        "Orthodontics is a branch of dentistry that focuses on the diagnosis, prevention, and treatment of dental and facial irregularities, primarily malocclusions (improper bites), through the use of braces, clear aligners, and other orthodontic appliances.",
    },
    {
      question: "What are malocclusions?",
      answer:
        "Malocclusions are misalignments of the teeth or improper relationships between the teeth of the upper and lower dental arches.",
    },
    {
      question: "When should I consider orthodontic treatment?",
      answer:
        "Orthodontic treatment can be considered at any age, but it is typically most effective during childhood and adolescence when the teeth and jaws are still developing. ",
    },
  ];

  const { id } = useParams();

  useEffect(() => {
    const data = countries.filter((country) => country.id === id);
    console.log(data);
    if (data) {
      data.map((hero) => setCountryFlag(hero.img));
    }
  }, [id]);

  const toogle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  const getFilterCity = () => {
    const data = Universities.filter((university) =>
      university?.country?.toLowerCase().match(id?.toLowerCase())
    );
    console.log(data);
    setFilterUnivesities([...data]);
    console.log(data);
  };

  useEffect(() => {
    getFilterCity();
  }, [id]);


  const onSubmit = (data) => {
    mutateAsync(data).then((resp) => {
      console.log("data ", resp?.status);
      if(resp?.status===true){
        toast.success("Mail send sucessfully!");
        reset();
      }
    })
  }

  const download = () => {
    var link = document.createElement("a");
    link.download = 'brochure-download.pdf';
    link.href = brocherDowload;
    document.body.appendChild(link);
    link.click();
  }
  


  const handleDownloadBrochure = (value, university) => {
    setBrochureModal(true);
    setBrocherDowload(value);
    setValue('university', university);
    setValue('download', 'broucher downlod');
  }

  const handleApplyNow = (value) => {
    setApplyModal(true);
    setValue('university', value);
  }



  

  return (
    <>
      {isLoading ?  <Loader /> :""}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blog Detail | {data?.data?.title}</title>
      </Helmet>
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>Blog Detail | {data?.data?.title}</title>
        <meta name="description" content="ims education about page" />
        <link rel="canonical" href={`https://imseduglobal.com/blog/${data?.data?.slug}`} />
        <meta
          property="og:description"
          content="Freelancing service that offers graphic design, illustration, and some small web design work digitally"
        />
        <meta property="og:url" content={`https://imseduglobal.com/blog/${data?.data?.slug}`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="BradGradGraphics" />
        <meta property="og:image" content="../images/SplashPgConc.png" />
      </Helmet> */}
      <section className="mbbs_section">
        <div className="container">
          <div className="section_wrapper">
            <div>
              {id && <img src={flag} alt="" className="country_flag" />}
            </div>
            <div>
              <h3>
                <span>{data?.data?.title}</span>
              </h3>
              <ul className="mbbs_list">
                <li>
                  <Link className="/">Home</Link>
                </li>
                <li>/</li>
                <li>{data?.data?.title}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div className="mbbs_section_wrapper pt-70 pb-70">
        <div className="container">
          <div className="mbbs_details_block">
            <div className="mbbs_section_left">
              <div className="social_media_icons">
                <span>
                  Share
                  <LuShare2 />:
                </span>
                <Link className="fb_share">
                  <FaFacebook />
                </Link>
                <Link className="insta_share">
                  <FaInstagramSquare />
                </Link>
                <Link className="twitter_share">
                  <FaTwitter />
                </Link>
                <Link className="what_share">
                  <FaWhatsapp />
                </Link>
                <Link className="linked_share">
                  <FaLinkedin />
                </Link>
              </div>
              <img
                src={data?.data?.blog_image}
                className="banner-feature-image"
                alt=""
                style={{ width: "100%" }}
              />
              <div className="mbbs_quick_links">
                <div className="header_content_mbbs">
                  <h2>Overview</h2>
                </div>
                <div
                  className=""
                  dangerouslySetInnerHTML={{ __html: data?.data?.long_desc1 }}
                ></div>
                <div
                  className=""
                  dangerouslySetInnerHTML={{ __html: data?.data?.long_desc2 }}
                ></div>
              </div>

              <div className="top_univarsity">
                <div className="header_content_mbbs">
                  <h2>Top Universities in {id}</h2>
                </div>
                <div>
                  {data?.university?.map((row) => {
                    return (
                      <div className="university_card">
                        <div className="card_wrapper">
                          <div className="university_image">
                            <img
                              src={row?.feature_image}
                              alt=""
                              width="100%"
                              height="100%"
                            />

                            <div className="uni_overlay">
                              <span>{row?.type}</span>
                            </div>
                            <div className="seats_avalable">
                              <span>Admission Open</span>
                            </div>
                          </div>
                          <div className="university_detail">
                            <div className="blogtopuniversity">
                              <h3>{row?.title}</h3>
                              <div className="uni_subtitle">
                                <div className="uni_course">
                                  <span className="univer_resp"> Course :</span>{" "}
                                  <span>{row.course_id}</span>
                                </div>
                                <div>|</div>
                                <div className="uni_degree">
                                  <span className="univer_resp"> Degree: </span>
                                  <span>{row?.degree}</span>
                                </div>
                                <div>|</div>
                                <div className="uni_country">
                                  <span className="univer_resp"> Country:</span>{" "}
                                  <span>{row?.country}</span>
                                </div>
                              </div>
                            </div>
                            <div className="university_specification">
                              <div className="univer_spec_left">
                                <div className="spe_wrap">
                                  <div className="spe_title">
                                    University Type
                                  </div>
                                  <div className="spec_ans">
                                    : {row?.university_type}
                                  </div>
                                </div>
                                <div className="spe_wrap">
                                  <div className="spe_title">
                                    University Grade{" "}
                                  </div>
                                  <div className="spec_ans">
                                    : {row?.university_grade}
                                  </div>
                                </div>
                                <div className="spe_wrap">
                                  <div className="spe_title">Estd.Year</div>
                                  <div className="spec_ans">
                                    : {row?.estd_year}
                                  </div>
                                </div>
                                <div className="spe_wrap">
                                  <div className="spe_title">World Rank </div>
                                  <div className="spec_ans">
                                    : {row?.world_rank}
                                  </div>
                                </div>
                                <div className="spe_wrap">
                                  <div className="spe_title">Loan </div>
                                  <div className="spec_ans">: Available</div>
                                </div>
                              </div>
                              <div className="univer_spec_right">
                                <div className="spe_wrap">
                                  <div className="spe_title">Intake</div>
                                  <div className="spec_ans">: Sep 24</div>
                                </div>
                                <div className="spe_wrap">
                                  <div className="spe_title">Duration</div>
                                  <div className="spec_ans">:5.8 Yrs</div>
                                </div>
                                <div className="spe_wrap">
                                  <div className="spe_title">Fee</div>
                                  <div className="spec_ans">: {row?.fee}</div>
                                </div>
                                <div className="spe_wrap">
                                  <div className="spe_title">Medium</div>
                                  <div className="spec_ans">:English</div>
                                </div>
                                <div className="spe_wrap">
                                  <div className="spe_title">
                                    Specialization
                                  </div>
                                  <div className="spec_ans">
                                    : {row?.specialization}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <p class="blink">
                              Admission Helpline : <span>+91 8284972098</span>
                            </p>
                          </div>
                          <div className="university_sub_detail">
                            <div className="university_btn_grp">
                              <a
                                className="download_btn"
                                onClick={() => handleDownloadBrochure(row?.brochure, row?.title)}
                              >
                                Brochure
                              </a>

                              <a
                                className="apply_now_btn"
                                onClick={() => handleApplyNow(row?.title)}
                              >
                                Apply Now
                              </a>
                              <Link
                                to={`/university/${row?.slug}`}
                                className="view_btn"
                              >
                                View Detail
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* Blogs */}
              <div className="mbbs_overview" id="documents-required">
                <div className="header_content_mbbs">
                  <h2>Explore Our's Blogs</h2>
                </div>
                <div className="blog_cards_wrapper">
                  {data?.explore?.map((row) => (
                    <div className="blog_item">
                      <div className="blog_card">
                        <div className="blog_card_thumb">
                          <img src={row?.blog_image} alt="" />
                          <div className="blog_card_content">
                            <h3>{row?.title}</h3>
                            <p>{row?.excert}</p>
                            <Link
                              to={`/blog/${row?.slug}`}
                              className="blog_link"
                            >
                              Learn More
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* faqs */}
              <div className="faq_section">
                <div className="header_content_mbbs">
                  <h2>Faq's</h2>
                </div>
                <div className="faq_wrapper">
                  <div className="accordion">
                    {faqs.map((faq, i) => {
                      return (
                        <div className="item" key={i}>
                          <div
                            className={selected === i ? "title show" : "title"}
                            onClick={() => toogle(i)}
                          >
                            <label>{faq.question}</label>
                            <strong>
                              {selected === i ? (
                                <RiArrowDropUpLine size="30px" />
                              ) : (
                                <RiArrowDropDownLine size="30px" />
                              )}
                            </strong>
                          </div>
                          <div
                            className={
                              selected === i ? "content show" : "content"
                            }
                          >
                            {faq.answer}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="mbbs_section_right">
              <div className="blog_wrapper">
                <h3 className="blog-title">Latest Blog</h3>
                <div className="blog_content_wrapper">
                  {data?.latestblog.map((row) => (
                    <div className="blog_content">
                      <div className="blog_thumb">
                        <Link to={`/blog/${row?.slug}`}>
                          <img src={row?.blog_image} alt="" width='100px' />
                        </Link>
                      </div>
                      <div className="blog_cont">
                        <span>{<Moment format="DD-MM-YYYY"  date={row?.created_at} />}</span>
                        <h3 className="blog_head">
                         {row?.excert?.substring(0, 50)}
                        </h3>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="counsul_form_wrapp">
                <div className="title">
                  <h5>Get Free Councelling</h5>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-content">
                    <input type="hidden" name="mailtype" {...register("mailtype")} value="blogenquiry" />
                    <input type="hidden" name="title" {...register("title")} value={data?.data?.title} />
                    <input type="text" {...register("name", { required: true })} placeholder="Enter Full Name" />
                    {errors.name && <p>This field is required</p>}
                  </div>

                  <div className="form-content">
                  <input type="number" {...register('number', {
                      required:true,
                        pattern: {
                            value: /^[6-9]\d{9}$/,
                            message: 'Please enter a number',
                        },
                    })} placeholder="Enter Whatsapp Number" />
                    {errors.number && <p>10 digit number is required</p>}
                  </div>

                  <div className="form-content">
                    <input type="submit" name="Submit" className="apply_btn" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <section className="modal_wrapper">
          <div className="modal_container">
            <div className="modal_title">
              <h2>{data?.data?.title}</h2>
              <p>Get free consultation for your MBBS career! </p>
            </div>

            <button className="close_btn" onClick={() => setShowModal(false)}>
              <IoMdCloseCircleOutline fontSize="25px" />
            </button>
            <div className="my_modal">
              <div className="modal_thumb">
                <img src={data?.data?.blog_image} alt="" style={{ width: "100%" }} />
              </div>
              <div className="modal_desc">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-content">
                    <input type="hidden" name="mailtype" {...register("mailtype")} value="blogenquiry" />
                    <input type="hidden" name="title" {...register("title")} value={data?.data?.title} />
                    <input type="text" {...register("name", { required: true })} placeholder="Enter Full Name" />
                    {errors.name && <p className="text-danger">This field is required</p>}
                  </div>
                  <div className="form-content">
                    <input type="number" {...register('number', {
                      required:true,
                        pattern: {
                            value: /^[6-9]\d{9}$/,
                            message: 'Please enter a number',
                        },
                    })} placeholder="Enter Whatsapp Number" />
                    {errors.number && <p className="text-danger">10 digit number is required</p>}

                  </div>
                  <button type="submit" className="apply_btn">Submit</button>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}


{brochureModal && (
        <section className="modal_wrapper">
        <div className="modal_container">
          <div className="modal_title">
            <h2>Download Brouchre</h2>
          </div>

          <button className="close_btn" onClick={() => setBrochureModal(false)}>
            <IoMdCloseCircleOutline fontSize="25px" />
          </button>
          <div className="my_modal">
            <div className="modal_thumb">
              <img src={brochure} alt="" style={{ width: "100%" }} />
            </div>
            <div className="modal_desc">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-content">
                  <input type="hidden" name="mailtype" {...register("mailtype")} value="downloadbrochure" />
                  <input type="text" name="name" {...register("name", { required: true })} placeholder="Enter Your Name" />
                  {errors.name && <p>This field is required</p>}
                </div>
                <div className="form-content">
                  <input type="email"  name="email" {...register("email", { required: true })} placeholder="Enter Your Email" />
                  {errors.email && <p>This email is required</p>}
                </div>
                <div className="form-content">
                  <input type="number" name="number" {...register("number", { required: true })} placeholder="Enter Whatsapp Number" />
                  {errors.number && <p>This number is required</p>}
                </div>
                <div className="form-content">
                  <input type="submit" value="Submit" className="apply_btn" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      )}


      <section>
          <div className="two_buttons_footer">
            <button type="button" className="enquiry_btn" onClick={() => setShowModal(true)}>Enquiry Now</button>
            <a href="tel:+918491992620" class="call btn btn-primary">
              <svg className="svg-inline--fa fa-phone" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                <path fill="currentColor" d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z">
                </path>
              </svg>&nbsp;&nbsp;&nbsp;+918284972098</a>
        </div>
      </section>
      {applyModal && (
        <section className="modal_wrapper">
          <div className="modal_container">
            <div className="modal_title">
              <h2>Apply And Book Your University</h2>
            </div>

            <button className="close_btn" onClick={() => setApplyModal(false)}>
              <IoMdCloseCircleOutline fontSize="25px" />
            </button>
            <div className="my_modal">
              <div className="modal_thumb">
                <img src={apply} alt="" style={{ width: "100%" }} />
              </div>
              <div className="modal_desc">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-content">
                    <input type="text" name="name" {...register("name", { required: true })} placeholder="Enter Full Name" />
                    {errors.name && <p>This field is required</p>}
                  </div>
                  <div className="form-content">
                    <input type="number" name="number" {...register("number", { required: true })} placeholder="Enter Whatsapp Number" />
                    {errors.number && <p>This number is required</p>}
                  </div>
                  <button className="apply_btn">Submit</button>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default BlogDetail;

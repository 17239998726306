import React from "react";
import "../assets/styles/About.css";
import desc from "../assets/images/about/desc.jpg";
import mission from "../assets/images/about/mission.jpg";
import history from "../assets/images/about/history.jpg";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About | Ims Education Global</title>
        <meta name="description" content="ims education about page" />
        <link rel="canonical" href="https://imseduglobal.com/about" />
      </Helmet>
      <section className="about_section">
        <div className="container">
          <div className="about_wrapper">
            <h3>About Us</h3>
          </div>
        </div>
      </section>

      <section className="about_section_wrapper pb-70 pt-70">
        <div className="container">
          <div className="about_desc">
            <div className="about_desc_left">
              <img src={desc} alt="" width="100%" />
            </div>
            <div className="about_desc_right">
              <h4 className="title">About Us</h4>
              <p>
                At IMS Education, we are passionate about empowering students to
                achieve their academic dreams. We are a team of experienced
                education consultants dedicated to providing personalized
                guidance and support throughout your educational journey.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* mission */}
      <section className="mission_section pt-70 pb-70">
        <div className="container">
          <h4 className="title" style={{ textAlign: "center" }}>
            Our Mission, Vision and Values
          </h4>
          <div className="mission_wrapper">
            <img src={mission} alt="" width="100%" />
          </div>
        </div>
      </section>

      {/* history */}

      <section className="history_section pt-70 pb-70">
        <div className="container">
          <h4 className="title">Our History</h4>
          <div className="history_wrapper">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Doloribus, odio maxime? Nulla quas, dolorem nostrum accusantium
              pariatur ratione vel delectus eaque vero laudantium, rem error
              mollitia quidem, ut expedita ad architecto animi molestias
              incidunt. Amet, esse ut? Ratione, ullam. Ullam obcaecati sed
              impedit quam repellat laborum dignissimos enim fuga nostrum
              laboriosam. Doloremque explicabo unde deserunt consequatur
              deleniti dicta quos quam nulla officiis natus? Ipsa, laudantium
              facilis eius quae sapiente dolore.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officiis
              quas provident, quasi, veritatis animi odit totam a assumenda
              eaque obcaecati cupiditate sequi error illum fugiat veniam
              voluptatem fugit eligendi dicta consequuntur magnam minima neque
              quae, ut delectus. Aspernatur, earum eos.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;

import React, { useState } from "react";
import imslogo from "../assets/images/logo/imslogo.png";
import "../assets/styles/ResposiveNavbar.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { Link } from "react-router-dom";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { useQuery } from "react-query";
import axios from "axios";

const ResposiveNavbar = () => {
  const [resposiveMenu, setResponsiveMenu] = useState(false);
  const [respMenuDropdown, setRespMenuDropdown] = useState(null);
  const [selecteCourse, setSelectedCourse] = useState(null);


  const openDropDown = (data) => {
    console.log(data);
    setRespMenuDropdown(data);
  };

  const handleLinkClick = () => {
    setResponsiveMenu(false);
  };

  const getStudyinAbroad = async () => {
    let url = process.env.REACT_APP_BASE_URL;
    let response = await axios.get(url + `country?study_in_abroad=Yes`);
    return response?.data;
  };

  const { data: studyAbroad} = useQuery({
    queryKey: ["GetStudyInAbroad"],
    queryFn: () => getStudyinAbroad(),
  });

  // const getallcourses = async () => {
  //   let url = process.env.REACT_APP_BASE_URL;
  //   let response = await axios.get(url + `courses`);
  //   return response?.data;
  // };

  // const { data: courses} = useQuery({
  //   queryKey: ["getcouses"],
  //   queryFn: () => getallcourses(),
  // });

  // Mbbs Aborad
  const getmbbslist = async () => {
    let url = process.env.REACT_APP_BASE_URL;
    let response = await axios.get(
      url + `blog-list?category_slug=mbbs-in-abroad`
    );
    console.log(response?.data);
    return response?.data;
  };

  const { data: mbbslist, isLoading: mbbslistload } = useQuery({
    queryKey: ["getmbbslist"],
    queryFn: () => getmbbslist(),
  });

  return (
    <section className="responsive_navbar roboto-regular">
      <div className="container">
        <nav className="responsive_navbar_section">
          <div className="navbar_left">
            <div className="logo_area">
              <img src={imslogo} alt="" width="100px" />
            </div>
          </div>
          <div className="navbar_right">
            <GiHamburgerMenu
              fontSize="20px"
              onClick={() => setResponsiveMenu(!resposiveMenu)}
            />
          </div>
        </nav>
        {resposiveMenu && (
          <div className="responsive_menu_wrapper animate__animated animate__fadeInDown">
            <div className="close_btn">
              <IoMdCloseCircleOutline
                fontSize="30px"
                onClick={() => setResponsiveMenu(!resposiveMenu)}
              />
            </div>

            <div className="resp_menu_list">
              <ul className="resp_menu_items">
                <li>
                  <Link to="/" onClick={handleLinkClick}>
                    Home
                  </Link>
                </li>
                <li
                  onClick={() =>
                    openDropDown(respMenuDropdown === "About" ? null : "About")
                  }
                >
                  <Link>About</Link>
                  {respMenuDropdown === "About" ? <FaMinus /> : <FaPlus />}
                </li>
                {respMenuDropdown === "About" && (
                  <ul className="resp_dropdown_list">
                    <li>
                      <Link to="/about" onClick={handleLinkClick}>
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link to="/team" onClick={handleLinkClick}>
                        Our Team
                      </Link>
                    </li>
                    <li>
                      <Link to="/testimonial" onClick={handleLinkClick}>
                        Testimonial
                      </Link>
                    </li>
                    {/* <li>
                      <Link>Career</Link>
                    </li> */}
                  </ul>
                )}
                <li
                  onClick={() =>
                    openDropDown(respMenuDropdown === "MBBS" ? null : "MBBS")
                  }
                >
                  <Link onClick={handleLinkClick}>MBBS in Abroad</Link>
                  {respMenuDropdown === "MBBS" ? (
                    <FaMinus onClick={() => setRespMenuDropdown(null)} />
                  ) : (
                    <FaPlus />
                  )}
                </li>

                {respMenuDropdown === "MBBS" && (
                  <ul className="resp_dropdown_list">
                    {mbbslist?.data?.map((row) => (
                      <li>
                        <img src={row?.country_image} alt="" />
                        <Link
                          to={`/blog/${row?.slug}`}
                          onClick={handleLinkClick}
                        >
                          {row?.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
                <li
                  onClick={() =>
                    openDropDown(respMenuDropdown === "Study" ? null : "Study")
                  }
                >
                  <Link>Study in Abroad</Link>
                  {respMenuDropdown === "Study" ? <FaMinus /> : <FaPlus />}
                </li>
                {respMenuDropdown === "Study" && (
                  <ul className="resp_dropdown_list">
                    {studyAbroad?.data.map((row, index) => (
                      <li onClick={handleLinkClick}>
                        <img src={row?.image} alt="" />
                        <Link
                          to={`/study-abroad/${row.slug}`}
                          onClick={() => setResponsiveMenu(null)}
                        >
                          Study In {row?.name}
                        </Link>
                      </li>
                    ))}
                    <li onClick={handleLinkClick}>
                      <Link
                        className="explore_btn"
                        to="/study-abroad-countries"
                        onClick={() => setResponsiveMenu(null)}
                      >
                        Explore More
                      </Link>
                    </li>
                  </ul>
                )}

                <li
                  onClick={() =>
                    openDropDown(
                      respMenuDropdown === "Services" ? null : "Services"
                    )
                  }
                >
                  <Link>Services</Link>
                  {respMenuDropdown === "Services" ? <FaMinus /> : <FaPlus />}
                </li>
                {respMenuDropdown === "Services" && (
                  <ul className="resp_dropdown_list">
                    <li onClick={handleLinkClick}>
                      <Link to="/services/test preparation">
                        Test Preparation
                      </Link>
                    </li>
                    <li onClick={handleLinkClick}>
                      <Link to="/services/admission guidance">
                        Admission Guidance
                      </Link>
                    </li>
                    <li onClick={handleLinkClick}>
                      <Link to="/services/course selection">
                        Course Selection
                      </Link>
                    </li>
                    <li onClick={handleLinkClick}>
                      <Link to="/services/career councelling">
                        Career Councelling
                      </Link>
                    </li>
                    <li onClick={handleLinkClick}>
                      <Link to="/services/visa and travel assistance">
                        Visa and Travel Assistance
                      </Link>
                    </li>
                  </ul>
                )}

                {/* <li>
                  <Link>Colleges</Link>
                </li> */}
                <li
                  onClick={() =>
                    openDropDown(
                      respMenuDropdown === "Courses" ? null : "Courses"
                    )
                  }
                >
                  <Link>Study in India</Link>
                  {respMenuDropdown === "Courses" ? <FaMinus /> : <FaPlus />}
                </li>
                {respMenuDropdown === "Courses" && (
                  <ul className="resp_dropdown_list">
                    <li
                      onClick={() =>
                        setSelectedCourse(
                          selecteCourse === "engineering" ? null : "engineering"
                        )
                      }
                    >
                      <Link onClick={handleLinkClick}>Engineering</Link>
                      {selecteCourse === "engineering" ? (
                        <FaMinus />
                      ) : (
                        <FaPlus />
                      )}
                    </li>
                    {selecteCourse === "engineering" && (
                      <ul className="resp_dropdown_list sub_dropdown_n">
                        <li>
                          <Link onClick={handleLinkClick}>BE</Link>
                        </li>
                        <li>
                          <Link onClick={handleLinkClick}>ME</Link>
                        </li>

                        <li>
                          <Link onClick={handleLinkClick}>BTech</Link>
                        </li>
                        <li>
                          <Link onClick={handleLinkClick}>Mtech</Link>
                        </li>
                      </ul>
                    )}
                    <li
                      onClick={() =>
                        setSelectedCourse(
                          selecteCourse === "management" ? null : "management"
                        )
                      }
                    >
                      <Link>Management</Link>
                      {selecteCourse === "management" ? (
                        <FaMinus />
                      ) : (
                        <FaPlus />
                      )}
                    </li>
                    {selecteCourse === "management" && (
                      <ul className="resp_dropdown_list sub_dropdown_n">
                        <li>
                          <Link onClick={handleLinkClick}>MBA</Link>
                        </li>
                        <li>
                          <Link onClick={handleLinkClick}>BBA</Link>
                        </li>
                      </ul>
                    )}

                    <li
                      onClick={() =>
                        setSelectedCourse(
                          selecteCourse === "law" ? null : "law"
                        )
                      }
                    >
                      <Link onClick={handleLinkClick}>Law</Link>
                      {selecteCourse === "law" ? <FaMinus /> : <FaPlus />}
                    </li>

                    {selecteCourse === "law" && (
                      <ul className="resp_dropdown_list sub_dropdown_n">
                        <li>
                          <Link onClick={handleLinkClick}>LLB</Link>
                        </li>
                        <li>
                          <Link onClick={handleLinkClick}>LLM</Link>
                        </li>
                      </ul>
                    )}

                    <li
                      onClick={() =>
                        setSelectedCourse(
                          selecteCourse === "medical" ? null : "medical"
                        )
                      }
                    >
                      <Link>Medical</Link>
                      {selecteCourse === "medical" ? <FaMinus /> : <FaPlus />}
                    </li>

                    {selecteCourse === "medical" && (
                      <ul className="resp_dropdown_list sub_dropdown_n">
                        <li>MD</li>
                        <li>DO</li>
                        <li>PharmaD</li>
                        <li>MBBS</li>
                      </ul>
                    )}
                  </ul>
                )}

                <li>
                  <Link
                    onClick={() =>
                      openDropDown(
                        respMenuDropdown === "gallery" ? null : "gallery"
                      )
                    }
                  >
                    Gallery
                  </Link>
                  {selecteCourse === "gallery" ? <FaMinus /> : <FaPlus />}
                </li>
                {respMenuDropdown === "gallery" && (
                  <ul className="resp_dropdown_list">
                    <li>
                      <Link to="/gallery/seminar" onClick={handleLinkClick}>
                        Seminars
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/gallery/student-departure"
                        onClick={handleLinkClick}
                      >
                        Student Departure
                      </Link>
                    </li>
                    <li>
                      <Link to="/gallery/ims-office" onClick={handleLinkClick}>
                        IMS Offices
                      </Link>
                    </li>
                    {/* <li>
                      <Link>Career</Link>
                    </li> */}
                  </ul>
                )}
                <li>
                  <Link to="/contact" onClick={handleLinkClick}>
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default ResposiveNavbar;

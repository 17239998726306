import React from "react";
import "../assets/styles/Team.css";
import ceo from "../assets/images/team/ceo.jpg";
import { Flex, Progress } from "antd";
import t1 from "../assets/images/team/t1.jpg";
import t2 from "../assets/images/team/t2.jpg";
import t3 from "../assets/images/team/t3.jpg";
import t4 from "../assets/images/team/t4.jpg";
import { Helmet } from "react-helmet";

const Team = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Team | Ims Education Global</title>
        <meta name="description" content="ims education about page" />
        <link rel="canonical" href="https://imseduglobal.com/about" />
      </Helmet>
      <section className="team_section">
        <div className="container">
          <div className="team_wrap">
            <h3>Our Team</h3>
          </div>
        </div>
      </section>

      <section className="team_wrapper pt-70 pb-70">
        <div className="container">
          <div className="header_content">
            <h2>
              {" "}
              <span style={{ color: "orange" }}>Our</span> CEO
            </h2>
          </div>

          <div className="ceo_wrapper">
            <div className="ceo_first">
              <div className="ceo_thumb">
                <img src={ceo} alt="" />
              </div>
            </div>
            <div className="ceo_sec">
              <div className="name_wrap">
                <h3>Lorem, ipsum.</h3>
                <h5>CEO</h5>
              </div>
              <div className="desc_wrap">
                <p>
                  Our team is comprised of highly qualified and experienced
                  professionals who are passionate about education and student
                  success. We come from diverse backgrounds, each with a wealth
                  of knowledge and expertise to contribute.
                </p>
              </div>
            </div>
            <div className="ceo_third">
              <div className="ceo_quality">
                <p>Risk Taker</p>
                <Progress percent={50} status="active" />
              </div>
              <div className="ceo_quality">
                <p>Optimistic Nature</p>
                <Progress percent={70} status="exception" />
              </div>
              <div className="ceo_quality">
                <p>Controlled Emotion</p>
                <Progress percent={100} />
              </div>
              <div className="ceo_quality">
                <p>Trust Others</p>
                <Progress percent={50} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="management_team_wrapper pt-70 pb-70">
        <div className="container">
          <div className="header_content">
            <h2>
              <span style={{ color: "orange" }}>Our</span> Management Team
            </h2>
          </div>
          <div className="management_wrapper">
            <div className="management_team_items">
              <div className="team_item">
                <div className="team_card">
                  <div className="team_img">
                    <img src={t1} alt="" style={{ width: "100%" }} />
                  </div>
                  <div className="team_content">
                    <h4>lorem ipsu </h4>
                    <p>Managing Director</p>
                  </div>
                </div>
              </div>
              <div className="team_item">
                <div className="team_card">
                  <div className="team_img">
                    <img src={t2} alt="" style={{ width: "100%" }} />
                  </div>
                  <div className="team_content">
                    <h4>lorem ipsu </h4>
                    <p>Assistant Director</p>
                  </div>
                </div>
              </div>
              <div className="team_item">
                <div className="team_card">
                  <div className="team_img">
                    <img src={t3} alt="" style={{ width: "100%" }} />
                  </div>
                  <div className="team_content">
                    <h4>lorem ipsu </h4>
                    <p>Account Manager</p>
                  </div>
                </div>
              </div>
              <div className="team_item">
                <div className="team_card">
                  <div className="team_img">
                    <img src={t4} alt="" style={{ width: "100%" }} />
                  </div>
                  <div className="team_content">
                    <h4>lorem ipsu </h4>
                    <p>Finance Manager</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="management_team_wrapper pt-70 pb-70">
        <div className="container">
          <div className="header_content">
            <h2>
              <span style={{ color: "orange" }}>Our</span> Core Team
            </h2>
          </div>
          <div className="management_wrapper">
            <div className="management_team_items">
              <div className="team_item">
                <div className="team_card">
                  <div className="team_img">
                    <img src={t1} alt="" style={{ width: "100%" }} />
                  </div>
                  <div className="team_content">
                    <h4>lorem ipsu </h4>
                    <p>Managing Director</p>
                  </div>
                </div>
              </div>
              <div className="team_item">
                <div className="team_card">
                  <div className="team_img">
                    <img src={t2} alt="" style={{ width: "100%" }} />
                  </div>
                  <div className="team_content">
                    <h4>lorem ipsu </h4>
                    <p>Assistant Director</p>
                  </div>
                </div>
              </div>
              <div className="team_item">
                <div className="team_card">
                  <div className="team_img">
                    <img src={t3} alt="" style={{ width: "100%" }} />
                  </div>
                  <div className="team_content">
                    <h4>lorem ipsu </h4>
                    <p>Account Manager</p>
                  </div>
                </div>
              </div>
              <div className="team_item">
                <div className="team_card">
                  <div className="team_img">
                    <img src={t4} alt="" style={{ width: "100%" }} />
                  </div>
                  <div className="team_content">
                    <h4>lorem ipsu </h4>
                    <p>Finance Manager</p>
                  </div>
                </div>
              </div>
              <div className="team_item">
                <div className="team_card">
                  <div className="team_img">
                    <img src={t1} alt="" style={{ width: "100%" }} />
                  </div>
                  <div className="team_content">
                    <h4>lorem ipsu </h4>
                    <p>Managing Director</p>
                  </div>
                </div>
              </div>
              <div className="team_item">
                <div className="team_card">
                  <div className="team_img">
                    <img src={t2} alt="" style={{ width: "100%" }} />
                  </div>
                  <div className="team_content">
                    <h4>lorem ipsu </h4>
                    <p>Assistant Director</p>
                  </div>
                </div>
              </div>
              <div className="team_item">
                <div className="team_card">
                  <div className="team_img">
                    <img src={t3} alt="" style={{ width: "100%" }} />
                  </div>
                  <div className="team_content">
                    <h4>lorem ipsu </h4>
                    <p>Account Manager</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="management_team_wrapper pt-70 pb-70">
        <div className="container">
          <div className="header_content">
            <h2>
              <span style={{ color: "orange" }}>Our</span>Advisory Board
            </h2>
          </div>
          <div className="management_wrapper">
            <div className="management_team_items">
              <div className="team_item">
                <div className="team_card">
                  <div className="team_img">
                    <img src={t2} alt="" style={{ width: "100%" }} />
                  </div>
                  <div className="team_content">
                    <h4>lorem ipsu </h4>
                    <p>Assistant Director</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Team;

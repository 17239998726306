import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { LuShare2 } from "react-icons/lu";
import "../assets/styles/Mbbs.css";
import mbbsBanner from "../assets/images/banner/mbbsBanner.jpeg";
import {
  FaFacebook,
  FaLinkedin,
  FaRegCircleRight,
  FaSquare,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa6";
import { HiOutlineChevronDoubleRight } from "react-icons/hi2";
import eligibility from "../assets/images/mbbs/eligibility.jpg";
import RUSSIA from "../assets/images/flags/RUSSIA.png";
import BANGLADESH from "../assets/images/flags/BANGLADESH.png";
import Belize from "../assets/images/flags/Belize.png";
import China from "../assets/images/flags/China.png";
import nepal from "../assets/images/flags/nepal.png";
import Egypt from "../assets/images/flags/Egypt.png";
// import nepal from "../assets/images/flags/nepal.png";
import Georgia from "../assets/images/flags/Georgia.png";
import Khazakhstan from "../assets/images/flags/Khazakhstan.png";
import Kyrgyzstan from "../assets/images/flags/Kyrgyzstan.png";
import Philippins from "../assets/images/flags/Philippins.png";
import Romania from "../assets/images/flags/Romania.png";
import { FaInstagramSquare } from "react-icons/fa";
import blog from "../assets/images/blog/blog.png";
import blog2 from "../assets/images/blog/blog2.jpg";
import blog3 from "../assets/images/blog/blog3.jpg";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { IoMdCloseCircleOutline } from "react-icons/io";
import nepalImg from "../assets/images/modal/nepalImg.jpg";
import college from "../assets/images/mbbs/college.jpg";
import cost from "../assets/images/mbbs/cost.jpg";
import university from "../assets/images/universities/university.jpg";
import { Helmet } from "react-helmet";

const Mbbs = () => {
  const [flag, setCountryFlag] = useState("");
  const [selected, setSelected] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [filterUnivercities, setFilterUnivesities] = useState([]);

  const Universities = [
    {
      name: "Yashpal",
      country: "russia",
      course: "Medical",
      type: "Public",
    },
    {
      name: "Tridev",
      country: "china",
      course: "Engineering",
      type: "Public",
    },
    {
      name: "sofard",
      country: "bangladesh",
      course: "Law",
      type: "Public",
    },
    {
      name: "roxfard",
      country: "china",
      course: "Medical",
      type: "Private",
    },
    {
      name: "Siyue",
      country: "russia",
      course: "Teaching",
      type: "Private",
    },
    {
      name: "kiwtre",
      country: "russia",
      course: "Medical",
      type: "Private",
    },
  ];

  const countries = [
    {
      id: "nepal",
      img: nepal,
    },
    {
      id: "bangladesh",
      img: BANGLADESH,
    },
    {
      id: "china",
      img: China,
    },
    {
      id: "russia",
      img: RUSSIA,
    },
    {
      id: "philippins",
      img: Philippins,
    },
    {
      id: "khazakhstan",
      img: Khazakhstan,
    },
    {
      id: "kyrgyzstan",
      img: Kyrgyzstan,
    },
    {
      id: "georgia",
      img: Georgia,
    },

    {
      id: "egypt",
      img: Egypt,
    },
    {
      id: "romania",
      img: Romania,
    },
    {
      id: "belize",
      img: Belize,
    },
  ];

  const faqs = [
    {
      question: "What is orthodontics?",
      answer:
        "Orthodontics is a branch of dentistry that focuses on the diagnosis, prevention, and treatment of dental and facial irregularities, primarily malocclusions (improper bites), through the use of braces, clear aligners, and other orthodontic appliances.",
    },
    {
      question: "What are malocclusions?",
      answer:
        "Malocclusions are misalignments of the teeth or improper relationships between the teeth of the upper and lower dental arches.",
    },
    {
      question: "When should I consider orthodontic treatment?",
      answer:
        "Orthodontic treatment can be considered at any age, but it is typically most effective during childhood and adolescence when the teeth and jaws are still developing. ",
    },
  ];

  const { id } = useParams();

  useEffect(() => {
    const data = countries.filter((country) => country.id === id);
    console.log(data);
    if (data) {
      data.map((hero) => setCountryFlag(hero.img));
    }
  }, [id]);

  const toogle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  const getFilterCity = () => {
    const data = Universities.filter((university) =>
      university.country.toLowerCase().match(id.toLowerCase())
    );
    console.log(data);
    setFilterUnivesities([...data]);
    console.log(data);
  };

  useEffect(() => {
    getFilterCity();
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Studay in Abroad </title>
        <meta name="description" content="ims education about page" />
        <link rel="canonical" href="https://imseduglobal.com/about" />
      </Helmet>
      <section className="mbbs_section">
        <div className="container">
          <div className="section_wrapper">
            <div>
              {id && <img src={flag} alt="" className="country_flag" />}
            </div>
            <div>
              <h3>
                MBBS in <span> {id}</span>
              </h3>
              <ul className="mbbs_list">
                <li>
                  <Link className="/">Home</Link>
                </li>
                <li>/</li>
                <li>MBBS in {id}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div className="mbbs_section_wrapper pt-70 pb-70">
        <div className="container">
          <div className="mbbs_details_block">
            <div className="mbbs_section_left">
              <div className="social_media_icons">
                <span>
                  Share
                  <LuShare2 />:
                </span>
                <Link className="fb_share">
                  <FaFacebook />
                </Link>
                <Link className="insta_share">
                  <FaInstagramSquare />
                </Link>
                <Link className="twitter_share">
                  <FaTwitter />
                </Link>
                <Link className="what_share">
                  <FaWhatsapp />
                </Link>
                <Link className="linked_share">
                  <FaLinkedin />
                </Link>
              </div>
              <img src={mbbsBanner} alt="" style={{ width: "100%" }} />
              <div className="mbbs_quick_links">
                <div className="header_content_mbbs">
                  <h2>MBBS in {id} - Quick Links</h2>
                </div>
                <div className="quick_links_wrapper">
                  <div className="quick_link_left">
                    <ul className="quick_links_lists">
                      <li>
                        <a href="#key-facts">Key Facts</a>
                      </li>
                      <li>
                        <a href="#advantages">Advantages</a>
                      </li>
                      <li>
                        <a href="#disadvantages">Disadvantages</a>
                      </li>
                    </ul>
                  </div>
                  <div className="quick_link_right">
                    <ul className="quick_links_lists">
                      <li>
                        <a href="#eligiblity">Eligibility</a>
                      </li>
                      <li>
                        <a href="#process">Process</a>
                      </li>
                      <li>
                        <a href="#documents-required">Documents Required</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="mbbs_overview">
                <div className="header_content_mbbs">
                  <h2>MBBS in {id} Overview</h2>
                </div>
                <div className="desc">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Assumenda sapiente dolorem excepturi rem amet dolores, esse
                    velit! Excepturi totam similique numquam aliquid earum
                    asperiores velit esse perspiciatis, dolor in, recusandae
                    sequi eaque unde sint provident error, dolorum repudiandae
                    accusantium facere ipsum porro doloremque tempora. Enim hic
                    vel beatae debitis tempora.
                  </p>
                  <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Neque accusantium molestias minima ad fugit ullam adipisci
                    nam, vel ipsam consequuntur sapiente hic libero quia amet
                    ducimus tenetur, corporis nihil magnam pariatur. Et
                    reprehenderit nobis a repellendus ipsam est. Ad beatae
                    quidem nemo natus, in provident consequuntur necessitatibus
                    atque iste aspernatur maiores repellat nulla. Enim illo
                    vero, dolorum labore voluptates consectetur ducimus autem
                    ratione iure repudiandae, est provident modi temporibus.
                    Explicabo tenetur sit, nobis porro numquam placeat assumenda
                    cumque error doloribus.
                  </p>
                </div>
              </div>
              <div className="mbbs_overview" id="key-facts">
                <div className="header_content_mbbs">
                  <h2>Key Facts about MBBS degree in {id}</h2>
                </div>
                <div className="desc">
                  <div className="table_responsive">
                    <table className="dynamic_table_content">
                      <tbody>
                        <tr>
                          <th>Parameter</th>
                          <th>Results</th>
                        </tr>

                        <tr>
                          <td>Medical Universities in {id}</td>
                          <td>90+</td>
                        </tr>
                        <tr>
                          <td>Medium of instruction</td>
                          <td>
                            <strong>Bilingual, English, Russian</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>Duration of Medicine</td>
                          <td>6 Years Incl. Internship</td>
                        </tr>
                        <tr>
                          <td>Degree awarded</td>
                          <td>MD Equivalent to MBBS</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="mbbs_overview">
                <div className="header_content_mbbs">
                  <h2>MBBS in {id} Vs MBBS in India</h2>
                </div>
                <div className="desc">
                  <div className="table_responsive">
                    <table className="dynamic_table_content">
                      <tbody>
                        <tr>
                          <th>Parameter</th>
                          <th>India</th>
                          <th>{id}</th>
                        </tr>

                        <tr>
                          <td>College Type</td>
                          <td>Public & Pvt</td>
                          <td>Only Public</td>
                        </tr>
                        <tr>
                          <td>Tuition Fee for the course</td>
                          <td>Pub Rs. 1-3 Lac, Pvt Rs. 60 Lacs - Rs. 1.5 Cr</td>
                          <td>Rs. 15-25 Lacs</td>
                        </tr>
                        <tr>
                          <td>Infrastructure</td>
                          <td>Good to Below Avg</td>
                          <td>Good</td>
                        </tr>
                        <tr>
                          <td>Degree Awarded</td>
                          <td>MBBS</td>
                          <td>MD Physician</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="mbbs_overview">
                <h2>MBBS in {id} for Indian Student</h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Facere adipisci corrupti cumque esse iusto repudiandae veniam
                  aliquam nam distinctio numquam.
                </p>

                <div style={{ margin: "15px 0px" }}>
                  <a className="apply_btn" onClick={() => setShowModal(true)}>
                    Learn More
                  </a>
                </div>
              </div>

              {/* admission process */}

              <div className="mbbs_overview" id="eligibility">
                <div className="desc">
                  <div className="mbbs_eligibility_wrapper">
                    <div className="mbbs_add_left">
                      <div className="header_content_mbbs">
                        <h2>Admission Process for MBBS in {id}</h2>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Facere adipisci corrupti cumque esse iusto
                          repudiandae veniam aliquam nam distinctio numquam.
                        </p>
                      </div>
                      <p>
                        <strong>(A) </strong>
                        <em>
                          Entrance Examination conducted by the Medical
                          Education Commission (Similar to NMC in India)
                        </em>{" "}
                        or
                        <br />
                        <strong>(B) </strong>
                        <em>
                          Direct admission through the NEET score of the current
                          year.
                        </em>
                      </p>
                    </div>
                    <div className="mbbs_add_right">
                      <div className="mbbs_thumb">
                        <img src={college} alt="" style={{ width: "100%" }} />
                      </div>
                    </div>
                  </div>

                  <h4 class="mt-4">
                    A. Entrance Examination Conducted by MEC:
                  </h4>
                  <p>
                    Students seeking admission to MBBS in Nepal without NEET
                    must appear for the MEC exam. The students who wish to study
                    MBBS in Nepal at Government Colleges must also appear for
                    the above Exam. After qualifying in the MEC exam, you are
                    eligible to get admitted to the below Govt college named:
                  </p>
                  <ul class="lists">
                    <li>BP Koirala Institute of Health Science, Dharan;</li>
                    <li>Institute of Medical Science (IOM), Kathmandu;</li>
                    <li>Patan Academy of Health Science, Kathmandu;</li>
                    <li>Karnali Academy of Health Science, Karnali,</li>
                    <li>
                      Nepalese Army Institute of Health Science, Kathmandu
                    </li>
                    <li>
                      Kathmandu University School of Medical Science, Kathmandu
                    </li>
                  </ul>
                  <p>
                    <strong>The Candidates need to</strong>
                  </p>
                  <ol>
                    <li>
                      Appear in the entrance examination conducted by MEC.
                    </li>
                    <li>
                      All candidates should score at least 50% in the entrance
                      examination and qualify for the merit list.
                    </li>
                    <li>
                      The allocation of seats and colleges will be done through
                      "MATCHING" mechanics as per the merit and priority order
                      selected by the Medical Education Commission (MEC)
                      candidate.
                    </li>
                    <li>
                      Exams are conducted only at the centers in Nepal, so
                      students need to travel to Nepal to appear for the
                      examination conducted by MEC.
                    </li>
                  </ol>
                  <h4 class="mt-4">
                    B. Directly through NEET Score for Indian Students
                  </h4>
                  <ol>
                    <li>
                      Students looking for direct admission to any private
                      medical school can get admission based on their NEET
                      qualifying score in India.
                    </li>
                    <li>
                      Only the present academic year's NEET score will be
                      considered for admission.
                    </li>
                    <li>
                      The General Category minimum NEET score will only be
                      considered for admission.
                    </li>
                    <li>
                      If applicants choose to apply via the medical entrance
                      exam rather than through direct NEET admission, preference
                      will be given to those who have passed the entrance exam
                      held by the MEC.
                    </li>
                  </ol>

                  <div style={{ margin: "15px 0px" }}>
                    <a className="apply_btn" onClick={() => setShowModal(true)}>
                      Apply Now
                    </a>
                  </div>
                </div>
              </div>

              {/* syllyabus */}

              <div className="mbbs_overview">
                <h3 class="mt-4">
                  Syllabus for NEPAL MBBS Entrance Examination
                </h3>
                <ul class="lists">
                  <li>
                    <strong>Question type</strong> : Single best response type
                    of multiple-choice questions
                  </li>
                  <li>
                    <strong>Cognitive Ratio</strong> : Recall: Understanding:
                    Application - 30:50:20
                  </li>
                  <li>
                    <strong>Option</strong> : Four options (A, B, C, D)
                  </li>
                  <li>
                    <strong>Number of questions</strong> : 200
                  </li>
                  <li>
                    <strong>Full marks</strong> : 200
                  </li>
                  <li>
                    <strong>Duration</strong> : 3 hours
                  </li>
                </ul>
                <a
                  class="btn btn-solid btnSubmit btn-success"
                  href="https://wa.me/+918269900555?text=Hi,"
                  target="_blank"
                >
                  <span>Connect with Counsellor</span>
                  <FaWhatsapp
                    style={{ margin: "0px 10px", fontSize: "20px" }}
                  />
                </a>
              </div>

              {/* Document required */}
              <div className="mbbs_overview" id="documents-required">
                <div className="header_content_mbbs">
                  <h2>Documents Required for admission in {id}</h2>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Facere adipisci corrupti cumque esse iusto repudiandae
                    veniam aliquam nam distinctio numquam.
                  </p>
                </div>
                <div className="desc">
                  <ul className="eligibility_lists">
                    <li>
                      <FaSquare />
                      10th & 12th transcript’s Attested copy required (Notary
                      Public, Education Ministry, India)
                    </li>
                    <li>
                      <FaSquare /> NEET Score Card
                    </li>
                    <li>
                      <FaSquare /> Passport Copy
                    </li>
                    <li>
                      <FaSquare /> Bank Statement (For VISA Purpose)
                    </li>
                    <li>
                      <FaSquare /> Medical Fitness Certificate (For VISA
                      Purposes)
                    </li>
                  </ul>
                </div>
                <div style={{ margin: "15px 0px" }}>
                  <a className="apply_btn" onClick={() => setShowModal(true)}>
                    <span>Enquiry Now</span>
                  </a>
                </div>
              </div>

              {/* cost */}
              <div className="mbbs_overview" id="eligibility">
                <div className="desc">
                  <div className="mbbs_eligibility_wrapper">
                    <div className="mbbs_add_right">
                      <div className="mbbs_thumb">
                        <img src={cost} alt="" style={{ width: "100%" }} />
                      </div>
                    </div>

                    <div
                      className="mbbs_add_left"
                      style={{ padding: "0px 10px" }}
                    >
                      <div className="header_content_mbbs">
                        <h2>Cost of MBBS in {id}</h2>
                        <p>
                          According to government regulations, the Nepal MBBS
                          fees for Indian students studying in Nepal are fixed
                          at USD 75,000, equivalent to INR 62 lakhs. It's
                          important to note that in addition to tuition fees,
                          students must also factor in the cost of living in
                          Nepal.
                        </p>
                        <p>
                          When considering all expenses, including hostel and
                          mess fees, the total cost of an MBBS in Nepal in
                          Indian rupees typically ranges from 65 to 70 lakhs,
                          depending on the location and one's lifestyle choices.
                          Despite these costs, it's worth noting that MBBS fees
                          in Nepal for Indian students remain comparatively
                          affordable compared to similar programs in other
                          countries.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* eligibility */}
              <div className="mbbs_overview" id="eligibility">
                <div className="header_content_mbbs">
                  <h2>MBBS in {id} eligibility for Indian students</h2>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Facere adipisci corrupti cumque esse iusto repudiandae
                    veniam aliquam nam distinctio numquam.
                  </p>
                </div>
                <div className="desc">
                  <div className="mbbs_eligibility_wrapper">
                    <div className="mbbs_eligi_left">
                      <div className="mbbs_thumb">
                        <img
                          src={eligibility}
                          alt=""
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <div className="mbbs_eligi_right">
                      <ul>
                        <li>
                          <HiOutlineChevronDoubleRight />{" "}
                          <strong>NEET Qualified</strong>: Students must be NEET
                          Qualified in any of the previous 3 years
                        </li>
                        <li>
                          <HiOutlineChevronDoubleRight />
                          <strong>Academic Grade</strong> : Most universities
                          ask for over <strong>50% in 10+2 grades</strong>
                        </li>
                        <li>
                          <HiOutlineChevronDoubleRight /> <strong>Age</strong>:
                          Candidate should be a minimum of 17 years
                        </li>
                        <li>
                          <HiOutlineChevronDoubleRight />{" "}
                          <strong>Entrance Exam</strong>: Some universities may{" "}
                          <strong>conduct Online Exams</strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="top_univarsity">
                <div className="header_content_mbbs">
                  <h2>Top Universities in {id}</h2>
                </div>
                <div>
                  {filterUnivercities.map((univercity) => {
                    return (
                      <div className="university_card">
                        <div className="card_wrapper">
                          <div className="university_image">
                            <img
                              src={university}
                              alt=""
                              width="100%"
                              height="100%"
                            />

                            <div className="uni_overlay">
                              <span>{univercity.type}</span>
                            </div>
                            <div className="seats_avalable">
                              <span>Admission Open</span>
                            </div>
                          </div>
                          <div className="university_detail">
                            <div className="univesity_title">
                              <h3>University of {univercity.name}</h3>
                              <div className="uni_subtitle">
                                <div className="uni_course">
                                  <span className="univer_resp"> Course :</span>{" "}
                                  <span>{univercity.course}</span>
                                </div>
                                <div>|</div>
                                <div className="uni_degree">
                                  <span className="univer_resp"> Degree: </span>
                                  <span>Bachler</span>
                                </div>
                                <div>|</div>
                                <div className="uni_country">
                                  <span className="univer_resp"> Country:</span>{" "}
                                  <span>{univercity.country}</span>
                                </div>
                              </div>
                            </div>
                            <div className="university_specification">
                              <div className="univer_spec_left">
                                <div className="spe_wrap">
                                  <div className="spe_title">
                                    University Type
                                  </div>
                                  <div className="spec_ans">
                                    : {univercity.type}
                                  </div>
                                </div>
                                <div className="spe_wrap">
                                  <div className="spe_title">
                                    University Grade{" "}
                                  </div>
                                  <div className="spec_ans">: A+</div>
                                </div>
                                <div className="spe_wrap">
                                  <div className="spe_title">Estd.Year</div>
                                  <div className="spec_ans">: 1906</div>
                                </div>
                                <div className="spe_wrap">
                                  <div className="spe_title">World Rank </div>
                                  <div className="spec_ans">: 2433</div>
                                </div>
                                <div className="spe_wrap">
                                  <div className="spe_title">Loan </div>
                                  <div className="spec_ans">: Available</div>
                                </div>
                              </div>
                              <div className="univer_spec_right">
                                <div className="spe_wrap">
                                  <div className="spe_title">Intake</div>
                                  <div className="spec_ans">: Sep 24</div>
                                </div>
                                <div className="spe_wrap">
                                  <div className="spe_title">Duration</div>
                                  <div className="spec_ans">:5.8 Yrs</div>
                                </div>
                                <div className="spe_wrap">
                                  <div className="spe_title">Fee</div>
                                  <div className="spec_ans">: 35.75 Lacs</div>
                                </div>
                                <div className="spe_wrap">
                                  <div className="spe_title">Medium</div>
                                  <div className="spec_ans">:English</div>
                                </div>
                                <div className="spe_wrap">
                                  <div className="spe_title">
                                    Specialization
                                  </div>
                                  <div className="spec_ans">: MBBS</div>
                                </div>
                              </div>
                            </div>

                            <p class="blink">
                              Admission Helpline : <span>+91 8284972098</span>
                            </p>
                          </div>
                          <div className="university_sub_detail">
                            <div className="university_btn_grp">
                              <a
                                className="download_btn"
                                // onClick={() => setShowModal(true)}
                              >
                                Brouchure
                              </a>

                              <a
                                className="apply_now_btn"
                                // onClick={() => setApplyModal(true)}
                              >
                                Apply Now
                              </a>
                              <Link
                                to={`/study-abroad/${id}/university/${univercity.name}`}
                                className="view_btn"
                              >
                                View Detail
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* advantages */}
              <div className="mbbs_overview" id="advantages">
                <div className="header_content_mbbs">
                  <h2>Advantages to Studying MBBS in {id}</h2>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Facere adipisci corrupti cumque esse iusto repudiandae
                    veniam aliquam nam distinctio numquam.
                  </p>
                </div>
                <div className="desc">
                  <div className="mbbs_advantage_wrapper">
                    <div className="mbbs_lists">
                      <ul>
                        <li>
                          <FaRegCircleRight /> <strong>Affordability</strong>:
                          Russia is the most affordable option among all the
                          study-abroad destinations for Medical students.
                        </li>
                        <li>
                          <FaRegCircleRight />{" "}
                          <strong>Quality of Education</strong> : Russia offers
                          better education for a similar budget to that of
                          Kyrgyzstan, Kazakhstan, Uzbekistan, etc.
                        </li>
                        <li>
                          <FaRegCircleRight /> <strong>NMC guidelines</strong>:
                          The MBBS Education in Russia fulfils all the New NMC
                          guidelines laid for the Licensing Exam in India (NExT)
                        </li>
                        <li>
                          <FaRegCircleRight />{" "}
                          <strong>Medicine Syllabus</strong>: Russia is the most
                          affordable option among all the study-abroad
                          destinations for Medical students.
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <FaRegCircleRight /> <strong>Affordability</strong>:
                          Russia is the most affordable option among all the
                          study-abroad destinations for Medical students.
                        </li>
                        <li>
                          <FaRegCircleRight /> <strong>Affordability</strong>:
                          Russia is the most affordable option among all the
                          study-abroad destinations for Medical students.
                        </li>
                        <li>
                          <FaRegCircleRight /> <strong>Affordability</strong>:
                          Russia is the most affordable option among all the
                          study-abroad destinations for Medical students.
                        </li>
                        <li>
                          <FaRegCircleRight /> <strong>Affordability</strong>:
                          Russia is the most affordable option among all the
                          study-abroad destinations for Medical students.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* disadvantage */}
              <div className="mbbs_overview" id="disadvantages">
                <div className="header_content_mbbs">
                  <h2>Disadvantages to Studying MBBS in {id}</h2>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Facere adipisci corrupti cumque esse iusto repudiandae
                    veniam aliquam nam distinctio numquam.
                  </p>
                </div>
                <div className="desc">
                  <div className="mbbs_advantage_wrapper">
                    <div className="mbbs_lists">
                      <ul>
                        <li>
                          <FaRegCircleRight /> <strong>Language Barrier</strong>
                          : English is not a common first language in Russia, so
                          basic conversation in daily life can be a major
                          concern.
                        </li>
                        <li>
                          <FaRegCircleRight />
                          <strong>Bilingual Program</strong> : Most Russian
                          universities run bilingual MBBS programs or are
                          entirely in the Russian language. Hence, the
                          University selection must be done very carefully.
                        </li>
                        <li>
                          <FaRegCircleRight />{" "}
                          <strong>USMLE & NExT Preparation</strong>: : Agents
                          misled Indian students by claiming that Russian
                          universities offer NEXT, USMLE, or PLAB coaching to
                          students, which is an utter lie.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* admission Process */}
              {/* <div className="mbbs_overview" id="process">
                <div className="header_content_mbbs">
                  <h2>Process of MBBS Admission in {id}</h2>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Facere adipisci corrupti cumque esse iusto repudiandae
                    veniam aliquam nam distinctio numquam.
                  </p>
                </div>
                <div className="desc">
                  <div className="mbbs_eligibility_wrapper">
                    <div className="admissin_process_thumb">
                      <img
                        src={admissionProcess}
                        alt=""
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div> */}

              {/* Blogs */}
              <div className="mbbs_overview" id="documents-required">
                <div className="header_content_mbbs">
                  <h2>Explore Our {id}'s Blogs</h2>
                </div>
                <div className="blog_cards_wrapper">
                  <div className="blog_item">
                    <div className="blog_card">
                      <div className="blog_card_thumb">
                        <img src={blog} alt="" />
                        <div className="blog_card_content">
                          <h3>Lorem ipsum dolor sit amet.</h3>
                          <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Repellat id facilis architecto recusandae vero
                            harum iure doloremque explicabo repellendus iste?
                          </p>
                          <Link className="blog_link">Learn More</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="blog_item">
                    <div className="blog_card">
                      <div className="blog_card_thumb">
                        <img src={blog2} alt="" />
                        <div className="blog_card_content">
                          <h3>Lorem ipsum dolor sit amet.</h3>
                          <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Repellat id facilis architecto recusandae vero
                            harum iure doloremque explicabo repellendus iste?
                          </p>
                          <Link className="blog_link">Learn More</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="blog_item">
                    <div className="blog_card">
                      <div className="blog_card_thumb">
                        <img src={blog3} alt="" />
                        <div className="blog_card_content">
                          <h3>Lorem ipsum dolor sit amet.</h3>
                          <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Repellat id facilis architecto recusandae vero
                            harum iure doloremque explicabo repellendus iste?
                          </p>
                          <Link className="blog_link">Learn More</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* faqs */}
              <div className="faq_section">
                <div className="header_content_mbbs">
                  <h2>Faq's</h2>
                </div>
                <div className="faq_wrapper">
                  <div className="accordion">
                    {faqs.map((faq, i) => {
                      return (
                        <div className="item">
                          <div
                            className={selected === i ? "title show" : "title"}
                            onClick={() => toogle(i)}
                          >
                            <label>{faq.question}</label>
                            <strong>
                              {selected === i ? (
                                <RiArrowDropUpLine size="30px" />
                              ) : (
                                <RiArrowDropDownLine size="30px" />
                              )}
                            </strong>
                          </div>
                          <div
                            className={
                              selected === i ? "content show" : "content"
                            }
                          >
                            {faq.answer}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="mbbs_section_right">
              <div className="blog_wrapper">
                <h3 className="blog-title">Latest Blog</h3>
                <div className="blog_content_wrapper">
                  <div className="blog_content">
                    <div className="blog_thumb">
                      <img src={blog} alt="" />
                    </div>
                    <div className="blog_cont">
                      <span>24 feb 2024</span>
                      <h3 className="blog_head">Lorem ipsum dolor sit amet.</h3>
                    </div>
                  </div>
                  <div className="blog_content">
                    <div className="blog_thumb">
                      <img src={blog2} alt="" />
                    </div>
                    <div className="blog_cont">
                      <span>24 feb 2024</span>
                      <h3 className="blog_head">Lorem ipsum dolor sit amet.</h3>
                    </div>
                  </div>
                  <div className="blog_content">
                    <div className="blog_thumb">
                      <img src={blog3} alt="" />
                    </div>
                    <div className="blog_cont">
                      <span>24 feb 2024</span>
                      <h3 className="blog_head">Lorem ipsum dolor sit amet.</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="counsul_form_wrapp">
                <div className="title">
                  <h5>Get Free Councelling</h5>
                </div>
                <form action="">
                  <div className="form-content">
                    <input type="text" placeholder="Enter Your Name" />
                  </div>

                  <div className="form-content">
                    <input type="number" placeholder="Enter Whatsapp Number" />
                  </div>

                  <a className="apply_btn">Submit</a>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <section className="modal_wrapper" onClick={() => setShowModal(false)}>
          <div className="modal_container">
            <div className="modal_title">
              <h2>MBBS in Nepal 2024-25</h2>
              <p>Get free consultation for your global career in Nepal </p>
            </div>

            <button className="close_btn" onClick={() => setShowModal(false)}>
              <IoMdCloseCircleOutline fontSize="25px" />
            </button>
            <div className="my_modal">
              <div className="modal_thumb">
                <img src={nepalImg} alt="" style={{ width: "100%" }} />
              </div>
              <div className="modal_desc">
                <form action="">
                  <div className="form-content">
                    <input type="text" placeholder="Enter Full Name" />
                  </div>
                  <div className="form-content">
                    <input type="number" placeholder="Enter Whatsapp Number" />
                  </div>

                  <a className="apply_btn">Submit</a>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Mbbs;

import React, { useState } from "react";
import "../assets/styles/Footer.css";
import { Link } from "react-router-dom";
import { RiArrowDropRightLine } from "react-icons/ri";
import imslogofooter from "../assets/images/logo/imslogofooter.png";
import { FaFacebook, FaTwitter } from "react-icons/fa6";
import { FaInstagramSquare, FaPhoneAlt } from "react-icons/fa";
import { IoLocationSharp, IoLogoYoutube } from "react-icons/io5";
import { BiSolidPhoneCall } from "react-icons/bi";
import { IoMdMail } from "react-icons/io";
import { FaMinus, FaPlus } from "react-icons/fa6";
import axios from "axios";
import { useQuery } from "react-query";
import india from "../assets/images/map/india.png";
import uk from "../assets/images/map/uk.png"
import nepal from "../assets/images/map/nepal.png"

const Footer = () => {
  const getStudyinAbroad = async () => {
    let url = process.env.REACT_APP_BASE_URL;
    let response = await axios.get(url + `country?study_in_abroad=Yes`);
    return response?.data;
  };

  const { data: studyAbroad, isLoading: isloadingAbroad } = useQuery({
    queryKey: ["GetStudyInAbroad"],
    queryFn: () => getStudyinAbroad(),
  });

  const getallcourses = async () => {
    let url = process.env.REACT_APP_BASE_URL;
    let response = await axios.get(url + `courses`);
    return response?.data;
  };

  // Mbbs Aborad
  const getmbbslist = async () => {
    let url = process.env.REACT_APP_BASE_URL;
    let response = await axios.get(
      url + `blog-list?category_slug=mbbs-in-abroad`
    );
    console.log(response?.data);
    return response?.data;
  };

  const { data: mbbslist, isLoading: mbbslistload } = useQuery({
    queryKey: ["getmbbslist"],
    queryFn: () => getmbbslist(),
  });

  const { data: courses, isLoading: courseload } = useQuery({
    queryKey: ["getcouses"],
    queryFn: () => getallcourses(),
  });

  const [dropdown, setDroopDown] = useState(null);
  return (
    <section className="footer_section ">
      <div className="container">
        <div className="footer_section_wrapper pt-100 pb-70">
          <div className="footer_items">
            <div
              className="footer_content"
              onClick={() =>
                setDroopDown(dropdown === "service" ? null : "service")
              }
            >
              <h5>Services</h5>
              <div className="dropdown">
                {dropdown === "service" ? <FaMinus /> : <FaPlus />}
              </div>
            </div>
            <ul
              className={
                dropdown === "service" ? "footer_menu show" : "footer_menu"
              }
            >
              <li>
                <RiArrowDropRightLine />
                <Link to="/services/test preparation">Test Preparation</Link>
              </li>
              <li>
                <RiArrowDropRightLine />
                <Link to="/services/admission guidance">
                  Admission Guidance
                </Link>
              </li>
              <li>
                <RiArrowDropRightLine />
                <Link to="/services/course selection">Course Selection</Link>
              </li>
              <li>
                <RiArrowDropRightLine />
                <Link to="/services/career councelling">
                  Career Councelling
                </Link>
              </li>
              <li>
                <RiArrowDropRightLine />
                <Link to="/services/visa and travel assistance">
                  Visa and Travel Assistance
                </Link>
              </li>
            </ul>

            {/* <div
              className="footer_content"

            >
              <h5>Courses</h5>

            </div>
            <ul
              className={
                dropdown === "courses" ? "footer_menu show" : "footer_menu"
              }
            >
              {courses?.data.map((row) => (
                <li>
                  <RiArrowDropRightLine />
                  <Link to={``}>{row?.name}</Link>
                </li>
              ))}
            </ul> */}
          </div>
          <div className="footer_items">
            <div
              className="footer_content"
              onClick={() => setDroopDown(dropdown === "mbbs" ? null : "mbbs")}
            >
              <h5>MBBS in Abroad</h5>
              <div className="dropdown">
                {dropdown === "mbbs" ? <FaMinus /> : <FaPlus />}
              </div>
            </div>

            <ul
              className={
                dropdown === "mbbs" ? "footer_menu show" : "footer_menu"
              }
            >
              {/* <li>
                <RiArrowDropRightLine />
                <Link>MBBS In Nepal</Link>
              </li> */}
              {mbbslist?.data?.map((row) => (
                <li>
                  <RiArrowDropRightLine />
                  <Link to={`/blog/${row?.slug}`}>{row?.title}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="footer_items">
            <div
              className="footer_content"
              onClick={() =>
                setDroopDown(dropdown === "abroad" ? null : "abroad")
              }
            >
              <h5>Study in Abroad</h5>
              <div className="dropdown">
                {dropdown === "abroad" ? <FaMinus /> : <FaPlus />}
              </div>
            </div>
            <ul
              className={
                dropdown === "abroad" ? "footer_menu show" : "footer_menu"
              }
            >
              {studyAbroad?.data?.map((row) => (
                <li>
                  <RiArrowDropRightLine />
                  <Link to={`/study-abroad/${row.slug}`}>
                    Study In {row?.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="footer_items">
            <div
              className="footer_content"

            >
              <h5>Courses</h5>

            </div>
            <ul
              className={
                dropdown === "courses" ? "footer_menu show" : "footer_menu"
              }
            >
              {courses?.data.map((row) => (
                <li>
                  <RiArrowDropRightLine />
                  <Link to={``}>{row?.name}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="footer_add_section">
        <div className="container">
          <h3 style={{ textAlign: "center" }}>Locate Us</h3>
          <div className="social_media_wrapper">
            <div className="footer_items">
              <div className="footer_add">
                <img src={india} alt="" />
                <strong style={{ color: "#f5821f" }}>India Office</strong>
                <p className="text-center">
                  <IoLocationSharp />

                  606,6th floor, Luv kush   <br />  Tower,Exhibition road-800001, Patna, Bihar
                </p>
                <p className="mt-1"><FaPhoneAlt /> +918284972098</p>
                <p className="mt-1"><FaPhoneAlt /> +919341058993 </p>
                <p className="mt-1"><FaPhoneAlt />  +91 99349 77238</p>
              </div>
            </div>
            <div className="footer_items">
              <div className="footer_add">
                <img src={uk} alt="" />
                <strong style={{ color: "#f5821f" }}> UK Office</strong>
                <p> <IoLocationSharp />
                  Peterborough, cambridgeshire
                  PE2 9HG</p>
                <p className="mt-1"><FaPhoneAlt /> +9779819860934 </p>
                <p className="mt-1"><FaPhoneAlt /> +9779817883116</p>
              </div>
            </div>
            <div className="footer_items">
              <div className="footer_add">
                <img src={nepal} alt="" />
                <strong style={{ color: "#f5821f" }}> Nepal Office</strong>
                <p> <IoLocationSharp />

                  Near  Sub-metropolitian City   <br />Office-45600  Janakpur,Nepal</p>
                <p className="mt-1"><FaPhoneAlt /> +447438226167</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer_mid_section">
        <div className="container">
          <div className="social_media_wrapper">
            <div className="footer_items">
              <img src={imslogofooter} alt="" width="150px" />
            </div>
            <div className="footer_items">
              <div className="social_media_ico">
                <FaFacebook fontSize="30px" />

                <FaInstagramSquare fontSize="30px" />
                <IoLogoYoutube fontSize="30px" />
                <FaTwitter fontSize="30px" />
              </div>
            </div>
            {/* <div className="footer_items">
              <div className="contact_info">
                <BiSolidPhoneCall fontSize="30px" />
                <div>
                  <p> +91 8284972098</p>
                  <p>+91 8284972098</p>
                  <p>+91 9341058993</p>
                </div>
              </div>
            </div> */}
            <div className="footer_items">
              <div className="contact_info">
                <IoMdMail fontSize="30px" />
                <p> info@imseducation.in</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer_end_section">
        <div className="container">
          <div className="footer_policies">
            <div className="col">
              <p>Terms & Conditions</p>
              <p>|</p>
              <p>Disclaimer</p>
              <p>|</p>
              <p>Privacy Terms</p>
            </div>
            <div className="col">
              <p>
                Created & Designed by{" "}
                <Link to="https://csgroups.in/" style={{ color: "#f5821f" }}>
                  CS Groups
                </Link>
              </p>
            </div>
            <div className="col">
              <p>©2024 Ims Education</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;

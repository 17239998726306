import {useEffect} from "react";
import { Link } from "react-router-dom";
import "../assets/styles/Country.css";
import RUSSIA from "../assets/images/flags/RUSSIA.png";
import BANGLADESH from "../assets/images/flags/BANGLADESH.png";
import Belize from "../assets/images/flags/Belize.png";
import CANADA from "../assets/images/flags/CANADA.png";
import China from "../assets/images/flags/China.png";
import nepal from "../assets/images/flags/nepal.png";
import Dubai from "../assets/images/flags/Dubai.png";
import Egypt from "../assets/images/flags/Egypt.png";
import Finland from "../assets/images/flags/Finland.png";
import Georgia from "../assets/images/flags/Georgia.png";
import Khazakhstan from "../assets/images/flags/Khazakhstan.png";
import Kyrgyzstan from "../assets/images/flags/Kyrgyzstan.png";
import Netherland from "../assets/images/flags/Netherland.png";
import Philippins from "../assets/images/flags/Philippins.png";
import Romania from "../assets/images/flags/Romania.png";
import UK from "../assets/images/flags/UK.png";
import USA from "../assets/images/flags/USA.png";
import France from "../assets/images/flags/France.png";
import NewZealand from "../assets/images/flags/NewZealand.png";
import "../assets/styles/Country.css";
import { HiUsers } from "react-icons/hi";
import { useQuery } from "react-query";
import axios from "axios";

const AbroadCountries = () => {
  const getAllCountries = async () => {
    let url = process.env.REACT_APP_BASE_URL;
    let response = await axios.get(url + `country`);
    return response?.data;
  };

  const { data, isLoading } = useQuery({
    queryKey: ["GetAllCountries"],
    queryFn: () => getAllCountries(),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="mbbs_section">
        <div className="container">
          <div className="section_wrapper">
            <div>
              <h3>Best Countries to Study Abroad</h3>
              <ul className="mbbs_list">
                <li>
                  <Link className="/">Home</Link>
                </li>
                <li>/</li>
                <li>Countries</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="countries_wrapper pt-70 pb-70">
        <div className="container">
          <div className="country_sec">
            {data?.data.map((row, index) => (
              <div className="country_item">
                <div className="country_card">
                  <div className="section_wrapper">
                    <div>
                      <Link to={`/study-abroad/${row.slug}`}>
                        <img src={row.image} alt="" className="country_flag" />
                      </Link>
                    </div>

                    <div className="coun_subtitle">
                      <h4>
                        <Link to={`/study-abroad/${row.slug}`}>{row.name}</Link>
                      </h4>
                      <p>
                        <span>
                          <HiUsers style={{ color: "red" }} />
                        </span>
                        18039 Indian Students
                      </p>
                    </div>
                  </div>
                  <div className="country_desc">
                    <p>{row?.short_desc}</p>
                  </div>
                  <div className="count_btn_grop">
                    <Link className="talk_conceller">Explore Universities</Link>
                    <span>|</span>
                    <Link className="talk_conceller">Talk to Counceller</Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default AbroadCountries;

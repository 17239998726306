import React from 'react'
import { Helmet } from 'react-helmet'

const Contact = () => {
    return (
        <>
        <div>Contact</div>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Blog Detail | Ims Education Global</title>
            <meta name="description" content="ims education about page" />
            <link rel="canonical" href="https://imseduglobal.com/about" />
        </Helmet>
      </>
    )
}

export default Contact
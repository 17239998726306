import React from "react";
import "../assets/styles/Testimonial.css";
import { Rate } from "antd";
import t1 from "../assets/images/testimonial/t1.png";
import t2 from "../assets/images/testimonial/t2.png";
import t3 from "../assets/images/testimonial/t3.png";
import { FaQuoteRight } from "react-icons/fa6";
import { Helmet } from "react-helmet";

const Testimonial = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Team | Ims Education Global</title>
        <meta name="description" content="ims education about page" />
        <link rel="canonical" href="https://imseduglobal.com/about" />
      </Helmet>
      <section className="testimonial_wrapper">
        <div className="container">
          <div className="team_wrap">
            <h3>Testimonial</h3>
          </div>
        </div>
      </section>

      <section className="testimonal_wrapper pt-70 pb-70">
        <div className="container">
          <div className="testimonial_cards">
            <div className="testimonial_item">
              <div className="testimonial_card">
                <div className="testimonial_quote">
                  <FaQuoteRight />
                </div>
                <div className="testimonial_thumb">
                  <img src={t2} alt="" />
                </div>
                <div className="testimonial_intro">
                  <div className="title">
                    <h4>Divya Gupta</h4>
                    <p>Placed</p>
                  </div>
                </div>
                <div className="testimonial_desc">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Temporibus totam iste vero! Culpa nulla dicta reprehenderit
                    minima dolores accusantium molestiae?
                  </p>
                </div>
                <div className="testimonial_rating">
                  <Rate disabled defaultValue={4} />
                </div>
              </div>
            </div>
            <div className="testimonial_item">
              <div className="testimonial_card">
                <div className="testimonial_quote">
                  <FaQuoteRight />
                </div>
                <div className="testimonial_thumb">
                  <img src={t1} alt="" />
                </div>
                <div className="testimonial_intro">
                  <div className="title">
                    <h4>Aman Zha</h4>
                    <p>Placed</p>
                  </div>
                </div>
                <div className="testimonial_desc">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Temporibus totam iste vero! Culpa nulla dicta reprehenderit
                    minima dolores accusantium molestiae?
                  </p>
                </div>
                <div className="testimonial_rating">
                  <Rate disabled defaultValue={4} />
                </div>
              </div>
            </div>
            <div className="testimonial_item">
              <div className="testimonial_card">
                <div className="testimonial_quote">
                  <FaQuoteRight />
                </div>
                <div className="testimonial_thumb">
                  <img src={t3} alt="" />
                </div>
                <div className="testimonial_intro">
                  <div className="title">
                    <h4>Rishikesh Raj</h4>
                    <p>Placed</p>
                  </div>
                </div>
                <div className="testimonial_desc">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Temporibus totam iste vero! Culpa nulla dicta reprehenderit
                    minima dolores accusantium molestiae?
                  </p>
                </div>
                <div className="testimonial_rating">
                  <Rate disabled defaultValue={4} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonial;

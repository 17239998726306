import React, { useEffect, useState } from "react";
import "../assets/styles/Services.css";
import { Link, useParams } from "react-router-dom";
import test from "../assets/images/services/test.jpg";
import { TiTick } from "react-icons/ti";
import { FaRegDotCircle } from "react-icons/fa";
import { Helmet } from "react-helmet";

const Services = () => {
  const { slug } = useParams();

  const [selectedService, setSelectedService] = useState([]);

  const services = [
    {
      title: "test preparation",
      img: test,
      features: [
        {
          feature: "Strong Focus On Concepts",
        },
        {
          feature: "Special Test Taking Strategies",
        },
        {
          feature: "Flexible Schedules And Fast Track Batches Available",
        },
        {
          feature: "Flexible Schedules And Fast Track Batches Available",
        },
      ],
    },
    {
      title: "admission guidance",
      img: test,
      features: [
        {
          feature: "Strong Focus On Concepts",
        },
        {
          feature: "Special Test Taking Strategies",
        },
        {
          feature: "Flexible Schedules And Fast Track Batches Available",
        },
        {
          feature: "Flexible Schedules And Fast Track Batches Available",
        },
      ],
    },
    {
      title: "course selection",
      img: test,
      features: [
        {
          feature: "Strong Focus On Concepts",
        },
        {
          feature: "Special Test Taking Strategies",
        },
        {
          feature: "Flexible Schedules And Fast Track Batches Available",
        },
        {
          feature: "Flexible Schedules And Fast Track Batches Available",
        },
      ],
    },
    {
      title: "career councelling",
      img: test,
      features: [
        {
          feature: "Strong Focus On Concepts",
        },
        {
          feature: "Special Test Taking Strategies",
        },
        {
          feature: "Flexible Schedules And Fast Track Batches Available",
        },
        {
          feature: "Flexible Schedules And Fast Track Batches Available",
        },
      ],
    },
    {
      title: "visa & travel assistance",
      img: test,
      features: [
        {
          feature: "Strong Focus On Concepts",
        },
        {
          feature: "Special Test Taking Strategies",
        },
        {
          feature: "Flexible Schedules And Fast Track Batches Available",
        },
        {
          feature: "Flexible Schedules And Fast Track Batches Available",
        },
      ],
    },
  ];

  useEffect(() => {
    const data = services.filter((item) => item.title === slug);
    setSelectedService(data);
    console.log(data);
  }, [slug]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Service | Ims Education Global</title>
        <meta name="description" content="ims education about page" />
        <link rel="canonical" href="https://imseduglobal.com/about" />
      </Helmet>
      <section className="services">
        <div className="container">
          <div className="section_wrapper">
            <h3>{slug}</h3>
          </div>
        </div>
      </section>

      <div className="service_wrapper pt-70 pb-70">
        <div className="container">
          {selectedService.map((service) => {
            return (
              <div className="service_wrapper_group">
                <div className="service_wrapper_left">
                  <div className="services_filter_card">
                    <div className="title">
                      <h4>Services</h4>
                    </div>
                    <ul className="service_filter_lists">
                      <li>
                        <FaRegDotCircle />
                        <Link to="/services/test preparation">
                          Test Preparation
                        </Link>
                      </li>
                      <li>
                        <FaRegDotCircle />
                        <Link to="/services/admission guidance">
                          Admission Guidance
                        </Link>
                      </li>
                      <li>
                        <FaRegDotCircle />
                        <Link to="/services/course selection">
                          Course Selection
                        </Link>
                      </li>
                      <li>
                        <FaRegDotCircle />
                        <Link to="/services/career councelling">
                          Career Counseling
                        </Link>
                      </li>
                      <li>
                        <FaRegDotCircle />
                        <Link to="/services/visa & travel assistance">
                          Visa & Travel Assistance
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="service_wrapper_right">
                  <div className="service_pro">
                    <div className="service_pro_left">
                      <div className="service_thumb">
                        <img
                          src={service.img}
                          alt=""
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <div className="service_pro_right">
                      <div className="featured_content">
                        <ul className="featured_content_lists">
                          {service.features.length &&
                            service.features.map((item) => {
                              return (
                                <li>
                                  <TiTick />
                                  {item.feature}
                                </li>
                              );
                            })}
                          {/* <li>
                                    {" "}
                                    <TiTick /> Special Test Taking Strategies
                                  </li>
                                  <li>
                                    <TiTick /> Flexible Schedules And Fast Track Batches
                                    Available
                                  </li>
                                  <li>
                                    {" "}
                                    <TiTick /> Personalized Adaptive Learning
                                  </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="services_content">
                    <div className="title">
                      <h3>{slug}</h3>
                    </div>
                    <div className="serv_desc">
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Aliquid, obcaecati? Delectus a, harum maxime
                        voluptate consequatur asperiores quisquam ab
                        necessitatibus natus, aliquam odit eum saepe. Cum
                        doloremque atque aspernatur architecto velit libero
                        temporibus repellendus officia. Reprehenderit harum
                        perferendis molestiae omnis at eveniet consequatur
                        dolor, alias, fuga veniam sunt saepe distinctio.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Services;
